import React, { useState } from "react";
import Modal from "react-responsive-modal";
import styled, { css } from "styled-components";
import { H1 } from "../layout/Typography";
import { QuestionCircle } from "styled-icons/bootstrap";
import kennitala from "kennitala";
import {
  AssignOwnerToPropertyMutation,
  AssignOwnerToPropertyMutationVariables,
  CreateAndAssignOwnerToPropertyMutation,
  CreateAndAssignOwnerToPropertyMutationVariables,
  GetOwnerCardBySocialNumberQuery,
  GetOwnerCardBySocialNumberQueryVariables,
  OwnerCard,
} from "../../../types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ownerCardBySocialNumber } from "../../queries/user";
import { BasicButton } from "../layout/Buttons";
import { useEffect } from "react";
import {
  assignOwnerToProperty,
  createAndAssignOwnerToProperty,
} from "../../queries/properties";
import { emailValid } from "../utils/email";
import { toast } from "react-toastify";

type AssignOwnerToPropertyModalProps = {
  open: boolean;
  onDone: () => void;
  propertyId: string;
};

const QuestionIcon = styled(QuestionCircle)`
  color: #000;
  height: 32px;
`;

const InnerModal = styled.div`
  margin: 30px;
`;

type InputProps = {
  hasError?: boolean;
  disabled?: boolean;
};
const Input = styled.input<InputProps>`
  height: 40px;
  width: 100%;
  display: block;
  border: ${(props: InputProps) =>
    props.hasError ? css`1px solid #a51818;` : css`1px solid #dedede;`};
  background: transparent;
  font-size: 16px;
  transition: 0.3s ease;
  padding-top: 6px;
  padding-bottom: 0px;
  padding-left: 10px;
  outline: none;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  border-bottom: ${(props: InputProps) =>
    props.hasError ? css`2px solid #a51818;` : css`2px solid #dedede;`};
  color: ${(props: InputProps) =>
    props.hasError ? css`#a51818;` : css`#000;`};
  margin-bottom: 15px;

  :disabled {
    color: #000;
    opacity: 0.6;
  }
`;

const Message = styled.div`
  margin: 18px;
  display: flex;
  align-items: center;
  width: 350px;

  svg {
    margin-right: 8px;
  }
`;

export const AssignOwnerToPropertyModal: React.FC<
  AssignOwnerToPropertyModalProps
> = ({ open, onDone, propertyId }) => {
  const [socialNumber, setSocialNumber] = useState<string>("");
  const [debouncedSocialNumber, setDebouncedSocialNumber] =
    useState<string>("");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [fetchedOwner, setFetchedOwner] = useState<OwnerCard>();

  const socialNumberValid = kennitala.isValid(socialNumber || "");

  // Clear on open
  useEffect(() => {
    setSocialNumber("");
    setName("");
    setFetchedOwner(undefined);
    setEmail("");
    setPhone("");
  }, [open]);

  // Update lazy query when chaning to a valid socialnumber.
  useEffect(() => {
    if (kennitala.isValid(socialNumber)) {
      setDebouncedSocialNumber(socialNumber);
      getOwnerCardForSocialNumber();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialNumber]);

  // Get Pottential owner that is on record.
  const [getOwnerCardForSocialNumber, { data }] = useLazyQuery<
    GetOwnerCardBySocialNumberQuery,
    GetOwnerCardBySocialNumberQueryVariables
  >(ownerCardBySocialNumber, {
    variables: {
      socialNumber: debouncedSocialNumber,
    },
    onCompleted(data) {
      setName(data.getOwnerCardBySocialNumber.name);
      setFetchedOwner(data.getOwnerCardBySocialNumber);
    },
    onError() {
      setFetchedOwner(undefined);
    },
  });

  const [createAndAssignOwnerToPropertyMutation] = useMutation<
    CreateAndAssignOwnerToPropertyMutation,
    CreateAndAssignOwnerToPropertyMutationVariables
  >(createAndAssignOwnerToProperty, {
    onCompleted() {
      onDone();
    },
    onError(...error) {
      console.error(`Error: ${error}`);
      toast.error(
        "Villa við að bæta íbúa við húsfélag. Hafðu samband við Eignarekstur."
      );
    },
  });

  const [assignOwnerToPropertyMutation] = useMutation<
    AssignOwnerToPropertyMutation,
    AssignOwnerToPropertyMutationVariables
  >(assignOwnerToProperty, {
    onCompleted() {
      onDone();
    },
    onError(...error) {
      console.error(`Error: ${error}`);
      toast.error(
        "Villa við að bæta íbúa við húsfélag. Hafðu samband við Eignarekstur."
      );
    },
  });

  return (
    <Modal open={open} onClose={() => onDone()} center>
      <InnerModal>
        <H1>Bæta við íbúa á húsfélag</H1>
        <p>Hér getur þú bætt við notanda á húsfélagið</p>

        <Input
          hasError={!socialNumberValid && socialNumber !== ""}
          value={socialNumber || ""}
          onChange={(e) => {
            setSocialNumber(e.currentTarget.value.replace(/\D/g, ""));
          }}
          placeholder="Kennitala"
        ></Input>

        <Input
          disabled={fetchedOwner !== undefined || !socialNumberValid}
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          placeholder="Nafn"
        ></Input>

        {!fetchedOwner && (
          <>
            <Input
              disabled={fetchedOwner !== undefined || !socialNumberValid}
              value={phone}
              onChange={(e) => setPhone(e.currentTarget.value)}
              placeholder="Sími"
            ></Input>

            <Input
              disabled={fetchedOwner !== undefined || !socialNumberValid}
              hasError={!emailValid(email) && email !== ""}
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              placeholder="Netfang"
            ></Input>
          </>
        )}

        <br />

        <BasicButton
          buttonType="primary"
          onClick={() => {
            if (fetchedOwner && fetchedOwner?.id) {
              assignOwnerToPropertyMutation({
                variables: {
                  propertyId: propertyId,
                  ownerId: fetchedOwner.id,
                },
              });
            } else {
              createAndAssignOwnerToPropertyMutation({
                variables: {
                  propertyId: propertyId,
                  owner: {
                    name,
                    socialNumber,
                    email,
                    phone,
                  },
                },
              });
            }
          }}
          disabled={!socialNumberValid && name !== ""}
          fluid
        >
          Bæta við sem íbúa í húsfélagið
        </BasicButton>

        <br />

        {fetchedOwner && socialNumberValid && (
          <Message>
            <QuestionIcon />
            <div>
              {data?.getOwnerCardBySocialNumber.name} er núþegar til á skrá hjá
              okkur svo þú þarft ekki að fylla inn upplýsingar um hann.
            </div>
          </Message>
        )}

        {socialNumberValid && !fetchedOwner && (
          <Message>
            <QuestionIcon />
            Íbúi með kennitöluna {socialNumber} er ekki til á skrá hjá okkur!
            Vinsamlegast sláðu inn réttar upplýsingar um íbúa, áður en þú bætir
            honum við sem íbúa í húsfélaginu.
          </Message>
        )}
      </InnerModal>
    </Modal>
  );
};
