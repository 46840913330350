import styled from "styled-components";
import { PatchExclamationFill } from "styled-icons/bootstrap";
import { Done } from "styled-icons/material-twotone";

export const ExclamationIcon = styled(PatchExclamationFill)`
  color: #333333;
  height: 12px;
`;

export const CheckIcon = styled(Done)`
  color: #4e4a4a;
  height: 12px;
`;

export const Container = styled.div`
  max-width: 1024px;
  margin: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin: 0 auto; /* Centers the container horizontally */
  width: 100%; /* Allows the container to shrink on smaller screens */
`;

export const Header = styled.h1`
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: #333333;
`;

export const InvoiceHeader = styled.div`
  padding: 16px;
  background-color: #f2f5f9;
  border-radius: 12px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex; /* Enables flexbox */
  justify-content: space-between; /* Distributes left and right sections */
  align-items: flex-start; /* Aligns items at the top of the container */
  gap: 20px; /* Adds space between left and right sections */
`;

export const InvoiceDetails = styled.div`
  flex: 1; /* Allows the details to take up equal space */

  h2 {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 10px;
    color: #333333;
  }

  p {
    font-size: 16px;
    margin: 5px 0;
    color: #121722;
  }

  .label {
    font-size: 12px;
    font-weight: 700;
    color: #60737d;
  }

  .approved {
    color: #4caf50;
  }

  .rejected {
    color: #ff4d4f;
  }
`;

export const InvoiceRightDetails = styled.div`
  flex: 1; /* Allows the right section to take up equal space */
  text-align: right; /* Aligns the text to the right */

  p {
    font-size: 16px;
    margin: 5px 0;
    color: #121722;
  }

  .label {
    font-size: 12px;
    font-weight: 700;
    color: #60737d;
  }
`;

export const FetchInvoiceLink = styled.div`
  margin-bottom: 20px;
  text-align: center;

  a {
    padding: 10px 20px;
    background-color: #007bff;
    color: #ffffff;
    border-radius: 4px;
    text-decoration: none;
  }
`;

export const NoteLabel = styled.div`
  font-size: 12px;
  color: #333333;
  padding: 16px;
  display: flex; /* Enables flexbox */
  align-items: center; /* Vertically centers the content */
  gap: 8px; /* Optional: Adds spacing between the icon and the text */
`;

export const NoteInput = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 12px;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 20px;
  resize: none;
  margin: 5px 0;
  box-sizing: border-box;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Button = styled.button<{ approve?: boolean }>`
  flex: 1;
  padding: 10px;
  margin: 0 5px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${(props) => (props.approve ? "#4CAF50" : "#FF4D4F")};
  color: #ffffff;

  &:hover {
    background-color: ${(props) => (props.approve ? "#45A049" : "#E33E40")};
  }

  &:disabled {
    background-color: #d3d3d3; /* Gray color for disabled state */
    color: #a9a9a9; /* Lighter gray text */
    cursor: not-allowed; /* Changes the cursor to indicate disabled state */
    opacity: 0.6; /* Optional: Makes the button appear "dimmed" */
  }
`;

export const InvoiceIframe = styled.iframe`
  width: 100%;
  height: 500px;
  border: 1px solid #cccccc;
  border-radius: 12px;
`;

export const WrapperSimpleSubmit = styled.div`
  display: flex; /* establish flex container */
  flex-direction: column; /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center; /* center items horizontally, in this case */
  margin: auto;
  width: 100%;
  height: 100%;
  .text {
    color: #757575;
    padding: 15px;
  }
`;
