import * as React from "react";
import styled from "styled-components";
import { LogOut } from "styled-icons/boxicons-regular";
import logo from "../../../assets/nytt-logo2.png";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { clearSession } from "../../store/system";
import * as AuthService from "../../services/AuthService";

const LogOutIcon = styled(LogOut)`
  color: #34495e;
  height: 25px;
  margin-right: 5px;
`;

const Wrapper = styled.div`
  padding: 10px;
  color: #34495e;
  display: flex;
  justify-content: space-between;
`;

const Logo = styled.img`
  height: 50px;
  margin-right: 10px;
`;

const NavItems = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const NavItem = styled.li`
  float: right;
  margin: 10px;
`;

const NavItemButton = styled.button`
  display: inline-block;
  margin: 0.1em;
  padding: 10px;
  border: 1px solid #ffffff;
  text-decoration: none;
  font-weight: 400;
  color: #000000;
  background-color: #fff;
  text-align: center;
  position: relative;
  cursor: pointer;
  &:hover {
  }
  &:active {
    background-color: #999999;
  }
`;

export const Header = () => {
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state: AppState) => state.system);

  return (
    <Wrapper>
      <a href="/">
        <Logo src={logo} alt="Heim" />
      </a>
      <NavItems>
        <NavItem>
          {loggedIn && (
            <NavItemButton
              onClick={() => {
                AuthService.logout().then(() => {
                  dispatch(clearSession());
                });
              }}
            >
              <LogOutIcon /> Skrá út
            </NavItemButton>
          )}
        </NavItem>
      </NavItems>
    </Wrapper>
  );
};

export default Header;
