import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store";
import { LoadingPage } from "../layout/Loading";
import { Page } from "../layout/Page";
import Header from "../layout/Header";
import { BasicCard } from "../cards/BasicCard";
import styled from "styled-components";
import { H1 } from "../layout/Typography";
import { clearSession } from "../../store/system/actions";
import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  GetProfileInfoQuery,
  GetProfileInfoQueryVariables,
  SendRequestNotificationMutationVariables,
  SendRequestNotificationMutation,
} from "../../../types";
import { getDashboardInfo } from "../../queries/dashboard";
import { sendRequestNotification } from "../../queries/user";
import { BasicButton } from "../layout/Buttons";

const CardContent = styled.div`
  text-align: center;
`;

const Form = styled.div`
  text-align: left;
  padding: 25px;
`;

type FormGroupProps = { valid?: boolean; noValidation?: boolean };
const FormGroup = styled.div<FormGroupProps>`
  position: relative;
  margin-top: 25px;
  label {
    top: 20px;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: 0.2s;
  }
  border-bottom: 1px solid
    ${(props) => (props.noValidation ? "gray" : props.valid ? "green" : "red")};
  input:focus + label,
  input:disabled + label,
  input:valid + label {
    top: 100%;
    margin-top: -55px;
    height: 30px;
    color: gray;
  }
`;
const FormLabel = styled.label`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: 0.2s;
  color: gray;
`;
const FormInput = styled.input`
  height: 40px;
  width: 100%;
  display: block;
  border: none;
  background: transparent;
  font-size: 16px;
  transition: 0.3s ease;
  padding-top: 6px;
  padding-bottom: 0px;
  outline: none;
`;

type MessageProps = { ok?: boolean };
const Message = styled(H1)<MessageProps>`
  color: ${(props) => (props.ok ? "#72b08e" : "#000000")};
`;

const BasicUserPage = () => {
  const dispatch = useDispatch();
  const { loggedIn, loggingIn } = useSelector(
    (state: AppState) => state.system
  );
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [requestedProperty, setRequestedProperty] = useState<string>();

  const dashboardProfileQuery = useQuery<
    GetProfileInfoQuery,
    GetProfileInfoQueryVariables
  >(getDashboardInfo);

  // Mutation: Update Profile From API
  const [sendRequestNotificationMutation] = useMutation<
    SendRequestNotificationMutation,
    SendRequestNotificationMutationVariables
  >(sendRequestNotification, {
    onCompleted(data) {},
    onError(...error) {},
  });

  const me = dashboardProfileQuery.data?.me;

  return (
    <>
      <Header />
      <Page>
        <div>
          {loggingIn ? (
            <LoadingPage />
          ) : loggedIn ? (
            <BasicCard>
              <CardContent>
                <Message>Þú ert ekki á skrá hjá okkur</Message>
                <p>
                  Aðeins skilgreindir notendur geta skráð sig inn á „mínar
                  síður“.
                </p>
                <p>
                  Vinsamlegast fylltu út eftirfarandi ef þú vilt senda beiðni um
                  skráningu:
                </p>
                <Form>
                  <FormGroup noValidation>
                    <FormInput
                      required
                      disabled
                      id="name"
                      value={me?.name || ""}
                    />
                    <FormLabel htmlFor="name">Nafn</FormLabel>
                  </FormGroup>

                  <FormGroup noValidation>
                    <FormInput
                      required
                      disabled
                      id="socialnumber"
                      value={me?.socialNumber || ""}
                    />
                    <FormLabel htmlFor="socialnumber">Kennitala</FormLabel>
                  </FormGroup>

                  <FormGroup noValidation>
                    <FormInput
                      required
                      id="email"
                      value={email || ""}
                      onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                    <FormLabel htmlFor="email">Netfang</FormLabel>
                  </FormGroup>

                  <FormGroup noValidation>
                    <FormInput
                      required
                      id="phone"
                      value={phone || ""}
                      onChange={(e) => setPhone(e.currentTarget.value)}
                    />
                    <FormLabel htmlFor="phone">Sími</FormLabel>
                  </FormGroup>

                  <FormGroup noValidation>
                    <FormInput
                      required
                      id="requestedProperty"
                      value={requestedProperty || ""}
                      onChange={(e) =>
                        setRequestedProperty(e.currentTarget.value)
                      }
                    />
                    <FormLabel htmlFor="requestedProperty">Húsfélag</FormLabel>
                  </FormGroup>
                </Form>
                <BasicButton
                  buttonType="primary"
                  onClick={() => {
                    sendRequestNotificationMutation({
                      variables: {
                        input: {
                          ownerName: me?.name || "Ekki vitað",
                          propertyName: requestedProperty || "Ekki vitað",
                          phoneNumber: phone,
                          email: email,
                        },
                      },
                    }).then(() => dispatch(clearSession()));
                  }}
                >
                  Senda beiðni
                </BasicButton>{" "}
                <BasicButton
                  buttonType="secondary"
                  onClick={() => {
                    dispatch(clearSession());
                  }}
                >
                  Hætta við
                </BasicButton>
              </CardContent>
            </BasicCard>
          ) : (
            <div></div>
          )}
        </div>
      </Page>
    </>
  );
};

export default BasicUserPage;
