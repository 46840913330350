// @ts-nocheck
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Upload: { input: any; output: any; }
};

export type Query = {
  __typename?: 'Query';
  /** Get Approval Invoice by ID and Code */
  approvalInvoice: ApprovalInvoice;
  /** Get owners connected to the logged in user. */
  connectedOwners?: Maybe<Array<ConnectedOwner>>;
  /**
   * Get all documents with Date Filter
   * @deprecated documentsByDateFilter is deprecated. Use documentsV2 instead.
   */
  documentsByDateFilter: Array<Document>;
  /** Get all documents with filtering */
  documentsV2: Array<Document>;
  /** Get Owner By Social Number (Only Basic Information) */
  getOwnerCardBySocialNumber: OwnerCard;
  /** Get Owners by Property ID (Only Basic Information) */
  getOwnerCardsByProperty: Array<OwnerCard>;
  /** Get Invoice approvals by property name. */
  invoiceApprovalsByPropertyName: Array<InvoiceApproval>;
  /** Get Invoice by Property and invoice number. */
  invoiceByPropertyNameAndInvoiceNo?: Maybe<InvoiceWithDetails>;
  /** Get Invoices by Property name */
  invoicesByPropertyName: Array<Invoice>;
  /** Get Information about the logged in user */
  me: Owner;
  /** Get connections to other owners for the logged in user. */
  ownerConnections?: Maybe<Array<OwnerConnection>>;
  /** Get all properties that the logged in owner has access to. */
  properties: Array<Property>;
  /** Get Property by ID, only works if you have access to the property */
  propertyById: Property;
};


export type QueryApprovalInvoiceArgs = {
  code: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type QueryDocumentsByDateFilterArgs = {
  filter: DateRangeFilter;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type QueryDocumentsV2Args = {
  filter?: InputMaybe<DocumentFilter>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type QueryGetOwnerCardBySocialNumberArgs = {
  socialNumber: Scalars['String']['input'];
};


export type QueryGetOwnerCardsByPropertyArgs = {
  propertyId: Scalars['String']['input'];
};


export type QueryInvoiceApprovalsByPropertyNameArgs = {
  propertyName: Scalars['String']['input'];
};


export type QueryInvoiceByPropertyNameAndInvoiceNoArgs = {
  invoiceNo: Scalars['String']['input'];
  propertyName: Scalars['String']['input'];
};


export type QueryInvoicesByPropertyNameArgs = {
  propertyName: Scalars['String']['input'];
  take: Scalars['Int']['input'];
};


export type QueryPropertyByIdArgs = {
  id: Scalars['String']['input'];
};

export type ApprovalInvoice = {
  __typename?: 'ApprovalInvoice';
  amount: Scalars['Int']['output'];
  createDate: Scalars['String']['output'];
  description: Scalars['String']['output'];
  dueDate: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  modifiedDate: Scalars['String']['output'];
  name: Scalars['String']['output'];
  origin: Scalars['String']['output'];
  property: PropertyBanner;
  propertyId: Scalars['String']['output'];
  status: ApprovalInvoiceStatus;
  type: Scalars['String']['output'];
};

export type PropertyBanner = {
  __typename?: 'PropertyBanner';
  address: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  registrationId?: Maybe<Scalars['String']['output']>;
};

export enum ApprovalInvoiceStatus {
  Approved = 'APPROVED',
  Paid = 'PAID',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type ConnectedOwner = {
  __typename?: 'ConnectedOwner';
  connectedToProperties: Array<ConnectedProperty>;
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  socialNumber: Scalars['String']['output'];
  userId?: Maybe<Scalars['ID']['output']>;
};

export type ConnectedProperty = {
  __typename?: 'ConnectedProperty';
  address: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  registrationId?: Maybe<Scalars['String']['output']>;
};

export type DateRangeFilter = {
  fromDate: Scalars['String']['input'];
  toDate: Scalars['String']['input'];
};

export type Document = {
  __typename?: 'Document';
  accessType: DocumentAccessType;
  createdDate: Scalars['String']['output'];
  description: Scalars['String']['output'];
  displayDate: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  modifiedDate: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export enum DocumentAccessType {
  Basic = 'BASIC',
  Full = 'FULL'
}

export type DocumentFilter = {
  fromDate?: InputMaybe<Scalars['String']['input']>;
  propertyId?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};

export type OwnerCard = {
  __typename?: 'OwnerCard';
  id?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  socialNumber: Scalars['String']['output'];
};

export type InvoiceApproval = {
  __typename?: 'InvoiceApproval';
  approvalMessage: Scalars['String']['output'];
  approvalNo: Scalars['String']['output'];
  approvalStatus: Scalars['String']['output'];
  approved: Scalars['Boolean']['output'];
  creditInvoice: Scalars['Boolean']['output'];
  date: Scalars['String']['output'];
  deliveryDate: Scalars['String']['output'];
  description: Scalars['String']['output'];
  documentNo: Scalars['String']['output'];
  invoiceToName: Scalars['String']['output'];
  invoiceToNo: Scalars['String']['output'];
  lineTotalAmountWithVat: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  totalAmount: Scalars['String']['output'];
  totalAmountWithVat: Scalars['String']['output'];
  vendorName: Scalars['String']['output'];
  vendorNo: Scalars['String']['output'];
};

export type InvoiceWithDetails = {
  __typename?: 'InvoiceWithDetails';
  amount: Scalars['String']['output'];
  details?: Maybe<InvoiceDetail>;
  dueDate: Scalars['String']['output'];
  invoiceNo: Scalars['String']['output'];
  remainingAmount: Scalars['String']['output'];
  socialNumber: Scalars['String']['output'];
};

export type InvoiceDetail = {
  __typename?: 'InvoiceDetail';
  description1?: Maybe<Scalars['String']['output']>;
  description2?: Maybe<Scalars['String']['output']>;
  lines: Array<InvoiceLine>;
  totalAmount: Scalars['String']['output'];
};

export type InvoiceLine = {
  __typename?: 'InvoiceLine';
  amount: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type Invoice = {
  __typename?: 'Invoice';
  amount: Scalars['String']['output'];
  dueDate: Scalars['String']['output'];
  invoiceNo: Scalars['String']['output'];
  remainingAmount: Scalars['String']['output'];
  socialNumber: Scalars['String']['output'];
};

export type Owner = {
  __typename?: 'Owner';
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  session: Session;
  socialNumber: Scalars['String']['output'];
  userId?: Maybe<Scalars['ID']['output']>;
  userType: UserType;
};

export type Session = {
  __typename?: 'Session';
  expiration: Scalars['String']['output'];
};

export enum UserType {
  Basic = 'BASIC',
  Customer = 'CUSTOMER'
}

export type OwnerConnection = {
  __typename?: 'OwnerConnection';
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  properties: Array<OwnerConnectionProperty>;
  socialNumber: Scalars['String']['output'];
  userId?: Maybe<Scalars['ID']['output']>;
};

/** Properties for owner connection */
export type OwnerConnectionProperty = {
  __typename?: 'OwnerConnectionProperty';
  address: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoices: Array<Invoice>;
  registrationId?: Maybe<Scalars['String']['output']>;
  socialNumberConnection: Scalars['String']['output'];
};


/** Properties for owner connection */
export type OwnerConnectionPropertyInvoicesArgs = {
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type Property = {
  __typename?: 'Property';
  access: AccessType;
  address: Scalars['String']['output'];
  bankAccounts?: Maybe<Array<BankAccount>>;
  bankClaims?: Maybe<Array<BankClaim>>;
  contacts: Array<PropertyContact>;
  documentsCount: Scalars['Int']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  informations: Array<PropertyInformation>;
  invoiceApprovals?: Maybe<Array<InvoiceApproval>>;
  invoices: Array<Invoice>;
  ownersCount: Scalars['Int']['output'];
  propertySupportRequests?: Maybe<Array<PropertySupportRequest>>;
  registrationId?: Maybe<Scalars['String']['output']>;
};


export type PropertyBankClaimsArgs = {
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export enum AccessType {
  Basic = 'basic',
  Full = 'full'
}

export type BankAccount = {
  __typename?: 'BankAccount';
  balanceAtDate: Scalars['String']['output'];
  bankAccountGroup: Scalars['String']['output'];
  bankAccountNo: Scalars['String']['output'];
  contact: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type BankClaim = {
  __typename?: 'BankClaim';
  amount: Scalars['String']['output'];
  bankAccountNo: Scalars['String']['output'];
  description: Scalars['String']['output'];
  entryType: Scalars['String']['output'];
  no: Scalars['String']['output'];
  open: Scalars['Boolean']['output'];
  postingDate: Scalars['String']['output'];
  remainingAmount: Scalars['String']['output'];
};

export type PropertyContact = {
  __typename?: 'PropertyContact';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  propertyId: Scalars['String']['output'];
  role?: Maybe<Scalars['String']['output']>;
};

export type PropertyInformation = {
  __typename?: 'PropertyInformation';
  createdAt: Scalars['String']['output'];
  key: Scalars['String']['output'];
  modifiedAt: Scalars['String']['output'];
  title: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type PropertySupportRequest = {
  __typename?: 'PropertySupportRequest';
  assignee: Scalars['String']['output'];
  date: Scalars['String']['output'];
  description: Scalars['String']['output'];
  finished: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  reporter: Scalars['String']['output'];
  summary: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Approve an invoice (Only works if you have full access to the property) */
  approveInvoice: Scalars['Boolean']['output'];
  /** Assign an connected owner to a property. */
  assignConnectedOwnerToProperty: Scalars['Boolean']['output'];
  /** Assign an owner to a property (Only work if logged in user has full access to the property) */
  assignOwnerToProperty: Scalars['Boolean']['output'];
  /** Connect another owner to the logged in owner */
  connectOwner: Scalars['Boolean']['output'];
  /**
   * Create and assign an owner to a property (Only work if logged in user has full access to the property)
   * Should be used when owner being assigned does not already exists in the Eignarekstur systems.
   */
  createAndAssignOwnerToProperty: CreateAndAssignResult;
  /** Create and connect and owner (Use if owner is not already registered in the Eignarekstur's systems.) */
  createAndConnectOwner: CreateAndAssignResult;
  /** Create a contact for a property (Only work if logged in user has full access to the property) */
  createPropertyContact: PropertyContact;
  /** Delete a contact for a property (Only work if logged in user has full access to the property) */
  deletePropertyContact: Scalars['Boolean']['output'];
  /** Disconnect an already connected owner from the logged in owner */
  disconnectConnectedOwner: Scalars['Boolean']['output'];
  /** Send and request access notification to Eignarekstur. */
  sendRequestAccessNotification: Scalars['Boolean']['output'];
  /** Submit approval or rejection of an invoice */
  submitInvoiceApproval: Scalars['Boolean']['output'];
  /** Unassign a connected owner from a property. */
  unassignConnectedOwnerFromProperty: Scalars['Boolean']['output'];
  /** Unassign an owner to a property (Only work if logged in user has full access to the property) */
  unassignOwnerFromProperty: Scalars['Boolean']['output'];
  /** Update logged in owner */
  updateOwner: Scalars['Boolean']['output'];
  /** Update a contact for a property (Only work if logged in user has full access to the property) */
  updatePropertyContact: PropertyContact;
};


export type MutationApproveInvoiceArgs = {
  approve: Scalars['Boolean']['input'];
  invoiceNo: Scalars['String']['input'];
  propertyName: Scalars['String']['input'];
  rejectReason?: InputMaybe<Scalars['String']['input']>;
  socialNumber: Scalars['String']['input'];
};


export type MutationAssignConnectedOwnerToPropertyArgs = {
  connectedOwnerId: Scalars['String']['input'];
  propertyId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAssignOwnerToPropertyArgs = {
  ownerId: Scalars['String']['input'];
  propertyId: Scalars['String']['input'];
};


export type MutationConnectOwnerArgs = {
  ownerId: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateAndAssignOwnerToPropertyArgs = {
  owner: OwnerAssignmentInput;
  propertyId: Scalars['String']['input'];
};


export type MutationCreateAndConnectOwnerArgs = {
  owner: OwnerAssignmentInput;
  reason?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreatePropertyContactArgs = {
  propertyContact: PropertyContactInput;
};


export type MutationDeletePropertyContactArgs = {
  propertyContactId: Scalars['String']['input'];
  propertyId: Scalars['String']['input'];
};


export type MutationDisconnectConnectedOwnerArgs = {
  ownerId: Scalars['String']['input'];
};


export type MutationSendRequestAccessNotificationArgs = {
  requestInput: RequestAccessNotificationInput;
};


export type MutationSubmitInvoiceApprovalArgs = {
  approval: ApprovalInvoiceStatus;
  code: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
  note: Scalars['String']['input'];
};


export type MutationUnassignConnectedOwnerFromPropertyArgs = {
  connectedOwnerId: Scalars['String']['input'];
  propertyId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUnassignOwnerFromPropertyArgs = {
  ownerId: Scalars['String']['input'];
  propertyId: Scalars['String']['input'];
};


export type MutationUpdateOwnerArgs = {
  owner: OwnerUpdateInput;
};


export type MutationUpdatePropertyContactArgs = {
  propertyContact: PropertyContactInput;
  propertyContactId: Scalars['String']['input'];
};

export type OwnerAssignmentInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  socialNumber: Scalars['String']['input'];
};

export type CreateAndAssignResult = {
  __typename?: 'CreateAndAssignResult';
  ownerId: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type PropertyContactInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

export type RequestAccessNotificationInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  ownerName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  propertyName: Scalars['String']['input'];
};

export type OwnerUpdateInput = {
  email: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type GetProfileInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileInfoQuery = { __typename?: 'Query', me: { __typename?: 'Owner', userId?: string | null, socialNumber: string, name: string, email?: string | null, phone?: string | null, userType: UserType, session: { __typename?: 'Session', expiration: string } }, properties: Array<{ __typename?: 'Property', address: string, id: string }>, connectedOwners?: Array<{ __typename?: 'ConnectedOwner', userId?: string | null, socialNumber: string, name: string, email?: string | null, phone?: string | null, connectedToProperties: Array<{ __typename?: 'ConnectedProperty', id: string, address: string, externalId?: string | null }> }> | null, ownerConnections?: Array<{ __typename?: 'OwnerConnection', userId?: string | null, socialNumber: string, name: string, email?: string | null, phone?: string | null, properties: Array<{ __typename?: 'OwnerConnectionProperty', id: string, address: string, externalId?: string | null }> }> | null };

export type GetDocumentsV2QueryVariables = Exact<{
  fromDate?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  propertyId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetDocumentsV2Query = { __typename?: 'Query', documentsV2: Array<{ __typename?: 'Document', id: string, name: string, type: string, mimetype: string, description: string, createdDate: string, modifiedDate: string, displayDate: string, accessType: DocumentAccessType }> };

export type GetApprovalInvoiceQueryVariables = Exact<{
  id: Scalars['String']['input'];
  code: Scalars['String']['input'];
}>;


export type GetApprovalInvoiceQuery = { __typename?: 'Query', approvalInvoice: { __typename?: 'ApprovalInvoice', id: string, name: string, description: string, mimetype: string, type: string, createDate: string, modifiedDate: string, propertyId: string, amount: number, origin: string, dueDate: string, status: ApprovalInvoiceStatus, property: { __typename?: 'PropertyBanner', id: string, registrationId?: string | null, externalId?: string | null, address: string } } };

export type ApproveInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['String']['input'];
  code: Scalars['String']['input'];
  note: Scalars['String']['input'];
  approval: ApprovalInvoiceStatus;
}>;


export type ApproveInvoiceMutation = { __typename?: 'Mutation', submitInvoiceApproval: boolean };

export type GetInvoiceLinesQueryVariables = Exact<{
  propertyName: Scalars['String']['input'];
  invoiceNo: Scalars['String']['input'];
}>;


export type GetInvoiceLinesQuery = { __typename?: 'Query', invoiceByPropertyNameAndInvoiceNo?: { __typename?: 'InvoiceWithDetails', invoiceNo: string, amount: string, dueDate: string, remainingAmount: string, details?: { __typename?: 'InvoiceDetail', description1?: string | null, description2?: string | null, totalAmount: string, lines: Array<{ __typename?: 'InvoiceLine', amount: string, description: string }> } | null } | null };

export type ApproveInvoiceLineMutationVariables = Exact<{
  propertyName: Scalars['String']['input'];
  socialNumber: Scalars['String']['input'];
  approve: Scalars['Boolean']['input'];
  invoiceNo: Scalars['String']['input'];
  rejectReason?: InputMaybe<Scalars['String']['input']>;
}>;


export type ApproveInvoiceLineMutation = { __typename?: 'Mutation', approveInvoice: boolean };

export type GetAllowedPropertiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllowedPropertiesQuery = { __typename?: 'Query', properties: Array<{ __typename?: 'Property', address: string, id: string }>, connectedOwners?: Array<{ __typename?: 'ConnectedOwner', userId?: string | null, socialNumber: string, name: string, email?: string | null, phone?: string | null, connectedToProperties: Array<{ __typename?: 'ConnectedProperty', id: string, address: string, externalId?: string | null }> }> | null };

export type InvoicesByPropertyNameQueryVariables = Exact<{
  propertyName: Scalars['String']['input'];
  take: Scalars['Int']['input'];
}>;


export type InvoicesByPropertyNameQuery = { __typename?: 'Query', invoicesByPropertyName: Array<{ __typename?: 'Invoice', invoiceNo: string, socialNumber: string, amount: string, dueDate: string, remainingAmount: string }> };

export type GetPropertiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPropertiesQuery = { __typename?: 'Query', properties: Array<{ __typename?: 'Property', id: string, address: string, externalId?: string | null, registrationId?: string | null, access: AccessType }> };

export type GetPropertiesForDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPropertiesForDashboardQuery = { __typename?: 'Query', documentsV2: Array<{ __typename?: 'Document', id: string, name: string, type: string, mimetype: string, description: string, createdDate: string, modifiedDate: string, displayDate: string, accessType: DocumentAccessType }>, properties: Array<{ __typename?: 'Property', id: string, address: string, externalId?: string | null, registrationId?: string | null, access: AccessType, documentsCount: number, ownersCount: number, contacts: Array<{ __typename?: 'PropertyContact', id: string, name: string, phone?: string | null, email?: string | null, role?: string | null, propertyId: string }>, propertySupportRequests?: Array<{ __typename?: 'PropertySupportRequest', id: string, summary: string, assignee: string, reporter: string, date: string, description: string, finished: boolean }> | null, informations: Array<{ __typename?: 'PropertyInformation', key: string, title: string, value: string, createdAt: string, modifiedAt: string }> }>, ownerConnections?: Array<{ __typename?: 'OwnerConnection', properties: Array<{ __typename?: 'OwnerConnectionProperty', id: string, address: string, externalId?: string | null }> }> | null };

export type GetPropertiesInvoicesForDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPropertiesInvoicesForDashboardQuery = { __typename?: 'Query', properties: Array<{ __typename?: 'Property', id: string, address: string, invoiceApprovals?: Array<{ __typename?: 'InvoiceApproval', approvalNo: string, approvalStatus: string }> | null, invoices: Array<{ __typename?: 'Invoice', invoiceNo: string, socialNumber: string, amount: string, dueDate: string, remainingAmount: string }> }>, ownerConnections?: Array<{ __typename?: 'OwnerConnection', properties: Array<{ __typename?: 'OwnerConnectionProperty', id: string, address: string, externalId?: string | null, invoices: Array<{ __typename?: 'Invoice', invoiceNo: string, socialNumber: string, amount: string, dueDate: string, remainingAmount: string }> }> }> | null };

export type GetPropertyFinancialsDetailQueryVariables = Exact<{
  propertyId: Scalars['String']['input'];
  bankClaimsTake?: InputMaybe<Scalars['Int']['input']>;
  bankClaimsSkip?: InputMaybe<Scalars['Int']['input']>;
  bankClaimsDateFrom?: InputMaybe<Scalars['String']['input']>;
  bankClaimsDateTo?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetPropertyFinancialsDetailQuery = { __typename?: 'Query', me: { __typename?: 'Owner', userId?: string | null, socialNumber: string }, propertyById: { __typename?: 'Property', id: string, address: string, externalId?: string | null, registrationId?: string | null, access: AccessType, bankAccounts?: Array<{ __typename?: 'BankAccount', bankAccountNo: string, name: string, contact: string, id: string, balanceAtDate: string, bankAccountGroup: string }> | null, bankClaims?: Array<{ __typename?: 'BankClaim', no: string, postingDate: string, bankAccountNo: string, description: string, amount: string, remainingAmount: string, open: boolean, entryType: string }> | null, invoiceApprovals?: Array<{ __typename?: 'InvoiceApproval', approvalNo: string, creditInvoice: boolean, invoiceToNo: string, invoiceToName: string, vendorNo: string, vendorName: string, date: string, documentNo: string, description: string, reference: string, deliveryDate: string, totalAmount: string, totalAmountWithVat: string, lineTotalAmountWithVat: string, approvalStatus: string, approvalMessage: string, approved: boolean }> | null } };

export type GetPropertyDetailQueryVariables = Exact<{
  propertyId: Scalars['String']['input'];
}>;


export type GetPropertyDetailQuery = { __typename?: 'Query', propertyById: { __typename?: 'Property', id: string, address: string, externalId?: string | null, registrationId?: string | null, access: AccessType, contacts: Array<{ __typename?: 'PropertyContact', id: string, name: string, role?: string | null, phone?: string | null, email?: string | null, propertyId: string }> } };

export type AssignOwnerToPropertyMutationVariables = Exact<{
  propertyId: Scalars['String']['input'];
  ownerId: Scalars['String']['input'];
}>;


export type AssignOwnerToPropertyMutation = { __typename?: 'Mutation', assignOwnerToProperty: boolean };

export type AssignConnectedOwnerToPropertyMutationVariables = Exact<{
  propertyId: Scalars['String']['input'];
  connectedOwnerId: Scalars['String']['input'];
}>;


export type AssignConnectedOwnerToPropertyMutation = { __typename?: 'Mutation', assignConnectedOwnerToProperty: boolean };

export type UnassignOwnerFromPropertyMutationVariables = Exact<{
  propertyId: Scalars['String']['input'];
  ownerId: Scalars['String']['input'];
}>;


export type UnassignOwnerFromPropertyMutation = { __typename?: 'Mutation', unassignOwnerFromProperty: boolean };

export type UnassignConnectedOwnerFromPropertyMutationVariables = Exact<{
  propertyId: Scalars['String']['input'];
  connectedOwnerId: Scalars['String']['input'];
}>;


export type UnassignConnectedOwnerFromPropertyMutation = { __typename?: 'Mutation', unassignConnectedOwnerFromProperty: boolean };

export type CreateAndAssignOwnerToPropertyMutationVariables = Exact<{
  owner: OwnerAssignmentInput;
  propertyId: Scalars['String']['input'];
}>;


export type CreateAndAssignOwnerToPropertyMutation = { __typename?: 'Mutation', createAndAssignOwnerToProperty: { __typename?: 'CreateAndAssignResult', success: boolean, ownerId: string } };

export type GetLoggedInUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoggedInUserQuery = { __typename?: 'Query', me: { __typename?: 'Owner', userId?: string | null, socialNumber: string, name: string, email?: string | null, phone?: string | null, userType: UserType, session: { __typename?: 'Session', expiration: string } } };

export type UpdateOwnerMutationVariables = Exact<{
  owner: OwnerUpdateInput;
}>;


export type UpdateOwnerMutation = { __typename?: 'Mutation', updateOwner: boolean };

export type SendRequestNotificationMutationVariables = Exact<{
  input: RequestAccessNotificationInput;
}>;


export type SendRequestNotificationMutation = { __typename?: 'Mutation', sendRequestAccessNotification: boolean };

export type GetOwnerCardsByPropertyQueryVariables = Exact<{
  propertyId: Scalars['String']['input'];
}>;


export type GetOwnerCardsByPropertyQuery = { __typename?: 'Query', getOwnerCardsByProperty: Array<{ __typename?: 'OwnerCard', id?: string | null, name: string, socialNumber: string }>, propertyById: { __typename?: 'Property', address: string, access: AccessType } };

export type GetContactsForPropertyQueryVariables = Exact<{
  propertyId: Scalars['String']['input'];
}>;


export type GetContactsForPropertyQuery = { __typename?: 'Query', propertyById: { __typename?: 'Property', address: string, contacts: Array<{ __typename?: 'PropertyContact', id: string, name: string, role?: string | null, email?: string | null, phone?: string | null, propertyId: string }> } };

export type DeleteContactsFromPropertyMutationVariables = Exact<{
  propertyId: Scalars['String']['input'];
  propertyContactId: Scalars['String']['input'];
}>;


export type DeleteContactsFromPropertyMutation = { __typename?: 'Mutation', deletePropertyContact: boolean };

export type UpdateContactsForPropertyMutationVariables = Exact<{
  propertyContactId: Scalars['String']['input'];
  propertyContact: PropertyContactInput;
}>;


export type UpdateContactsForPropertyMutation = { __typename?: 'Mutation', updatePropertyContact: { __typename?: 'PropertyContact', id: string, name: string, role?: string | null, phone?: string | null, email?: string | null, propertyId: string } };

export type CreateContactsForPropertyMutationVariables = Exact<{
  propertyContact: PropertyContactInput;
}>;


export type CreateContactsForPropertyMutation = { __typename?: 'Mutation', createPropertyContact: { __typename?: 'PropertyContact', id: string, name: string, role?: string | null, phone?: string | null, email?: string | null, propertyId: string } };

export type GetOwnerCardBySocialNumberQueryVariables = Exact<{
  socialNumber: Scalars['String']['input'];
}>;


export type GetOwnerCardBySocialNumberQuery = { __typename?: 'Query', getOwnerCardBySocialNumber: { __typename?: 'OwnerCard', id?: string | null, name: string, socialNumber: string } };

export type DisconnectOwnerMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DisconnectOwnerMutation = { __typename?: 'Mutation', disconnectConnectedOwner: boolean };

export type ConnectOwnerMutationVariables = Exact<{
  id: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
}>;


export type ConnectOwnerMutation = { __typename?: 'Mutation', connectOwner: boolean };

export type CreateAndConnectOwnerMutationVariables = Exact<{
  input: OwnerAssignmentInput;
  reason?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateAndConnectOwnerMutation = { __typename?: 'Mutation', createAndConnectOwner: { __typename?: 'CreateAndAssignResult', success: boolean, ownerId: string } };


export const GetProfileInfoDocument = gql`
    query GetProfileInfo {
  me {
    userId
    socialNumber
    name
    email
    phone
    userType
    session {
      expiration
    }
  }
  properties {
    address
    id
  }
  connectedOwners {
    userId
    socialNumber
    name
    email
    phone
    connectedToProperties {
      id
      address
      externalId
    }
  }
  ownerConnections {
    userId
    socialNumber
    name
    email
    phone
    properties {
      id
      address
      externalId
    }
  }
}
    `;

/**
 * __useGetProfileInfoQuery__
 *
 * To run a query within a React component, call `useGetProfileInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetProfileInfoQuery, GetProfileInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileInfoQuery, GetProfileInfoQueryVariables>(GetProfileInfoDocument, options);
      }
export function useGetProfileInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileInfoQuery, GetProfileInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileInfoQuery, GetProfileInfoQueryVariables>(GetProfileInfoDocument, options);
        }
export function useGetProfileInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetProfileInfoQuery, GetProfileInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProfileInfoQuery, GetProfileInfoQueryVariables>(GetProfileInfoDocument, options);
        }
export type GetProfileInfoQueryHookResult = ReturnType<typeof useGetProfileInfoQuery>;
export type GetProfileInfoLazyQueryHookResult = ReturnType<typeof useGetProfileInfoLazyQuery>;
export type GetProfileInfoSuspenseQueryHookResult = ReturnType<typeof useGetProfileInfoSuspenseQuery>;
export type GetProfileInfoQueryResult = Apollo.QueryResult<GetProfileInfoQuery, GetProfileInfoQueryVariables>;
export const GetDocumentsV2Document = gql`
    query GetDocumentsV2($fromDate: String, $toDate: String, $limit: Int!, $page: Int!, $propertyId: String) {
  documentsV2(limit: $limit, page: $page, filter: {fromDate: $fromDate, toDate: $toDate, propertyId: $propertyId}) {
    id
    name
    type
    mimetype
    description
    createdDate
    modifiedDate
    displayDate
    accessType
  }
}
    `;

/**
 * __useGetDocumentsV2Query__
 *
 * To run a query within a React component, call `useGetDocumentsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentsV2Query({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetDocumentsV2Query(baseOptions: Apollo.QueryHookOptions<GetDocumentsV2Query, GetDocumentsV2QueryVariables> & ({ variables: GetDocumentsV2QueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentsV2Query, GetDocumentsV2QueryVariables>(GetDocumentsV2Document, options);
      }
export function useGetDocumentsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentsV2Query, GetDocumentsV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentsV2Query, GetDocumentsV2QueryVariables>(GetDocumentsV2Document, options);
        }
export function useGetDocumentsV2SuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDocumentsV2Query, GetDocumentsV2QueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDocumentsV2Query, GetDocumentsV2QueryVariables>(GetDocumentsV2Document, options);
        }
export type GetDocumentsV2QueryHookResult = ReturnType<typeof useGetDocumentsV2Query>;
export type GetDocumentsV2LazyQueryHookResult = ReturnType<typeof useGetDocumentsV2LazyQuery>;
export type GetDocumentsV2SuspenseQueryHookResult = ReturnType<typeof useGetDocumentsV2SuspenseQuery>;
export type GetDocumentsV2QueryResult = Apollo.QueryResult<GetDocumentsV2Query, GetDocumentsV2QueryVariables>;
export const GetApprovalInvoiceDocument = gql`
    query GetApprovalInvoice($id: String!, $code: String!) {
  approvalInvoice(id: $id, code: $code) {
    id
    name
    description
    mimetype
    type
    createDate
    modifiedDate
    propertyId
    amount
    origin
    dueDate
    status
    property {
      id
      registrationId
      externalId
      address
    }
  }
}
    `;

/**
 * __useGetApprovalInvoiceQuery__
 *
 * To run a query within a React component, call `useGetApprovalInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetApprovalInvoiceQuery(baseOptions: Apollo.QueryHookOptions<GetApprovalInvoiceQuery, GetApprovalInvoiceQueryVariables> & ({ variables: GetApprovalInvoiceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApprovalInvoiceQuery, GetApprovalInvoiceQueryVariables>(GetApprovalInvoiceDocument, options);
      }
export function useGetApprovalInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApprovalInvoiceQuery, GetApprovalInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApprovalInvoiceQuery, GetApprovalInvoiceQueryVariables>(GetApprovalInvoiceDocument, options);
        }
export function useGetApprovalInvoiceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetApprovalInvoiceQuery, GetApprovalInvoiceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetApprovalInvoiceQuery, GetApprovalInvoiceQueryVariables>(GetApprovalInvoiceDocument, options);
        }
export type GetApprovalInvoiceQueryHookResult = ReturnType<typeof useGetApprovalInvoiceQuery>;
export type GetApprovalInvoiceLazyQueryHookResult = ReturnType<typeof useGetApprovalInvoiceLazyQuery>;
export type GetApprovalInvoiceSuspenseQueryHookResult = ReturnType<typeof useGetApprovalInvoiceSuspenseQuery>;
export type GetApprovalInvoiceQueryResult = Apollo.QueryResult<GetApprovalInvoiceQuery, GetApprovalInvoiceQueryVariables>;
export const ApproveInvoiceDocument = gql`
    mutation ApproveInvoice($invoiceId: String!, $code: String!, $note: String!, $approval: ApprovalInvoiceStatus!) {
  submitInvoiceApproval(invoiceId: $invoiceId, code: $code, note: $note, approval: $approval)
}
    `;
export type ApproveInvoiceMutationFn = Apollo.MutationFunction<ApproveInvoiceMutation, ApproveInvoiceMutationVariables>;

/**
 * __useApproveInvoiceMutation__
 *
 * To run a mutation, you first call `useApproveInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveInvoiceMutation, { data, loading, error }] = useApproveInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      code: // value for 'code'
 *      note: // value for 'note'
 *      approval: // value for 'approval'
 *   },
 * });
 */
export function useApproveInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<ApproveInvoiceMutation, ApproveInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveInvoiceMutation, ApproveInvoiceMutationVariables>(ApproveInvoiceDocument, options);
      }
export type ApproveInvoiceMutationHookResult = ReturnType<typeof useApproveInvoiceMutation>;
export type ApproveInvoiceMutationResult = Apollo.MutationResult<ApproveInvoiceMutation>;
export type ApproveInvoiceMutationOptions = Apollo.BaseMutationOptions<ApproveInvoiceMutation, ApproveInvoiceMutationVariables>;
export const GetInvoiceLinesDocument = gql`
    query GetInvoiceLines($propertyName: String!, $invoiceNo: String!) {
  invoiceByPropertyNameAndInvoiceNo(propertyName: $propertyName, invoiceNo: $invoiceNo) {
    invoiceNo
    amount
    dueDate
    remainingAmount
    details {
      description1
      description2
      totalAmount
      lines {
        amount
        description
      }
    }
  }
}
    `;

/**
 * __useGetInvoiceLinesQuery__
 *
 * To run a query within a React component, call `useGetInvoiceLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceLinesQuery({
 *   variables: {
 *      propertyName: // value for 'propertyName'
 *      invoiceNo: // value for 'invoiceNo'
 *   },
 * });
 */
export function useGetInvoiceLinesQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceLinesQuery, GetInvoiceLinesQueryVariables> & ({ variables: GetInvoiceLinesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceLinesQuery, GetInvoiceLinesQueryVariables>(GetInvoiceLinesDocument, options);
      }
export function useGetInvoiceLinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceLinesQuery, GetInvoiceLinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceLinesQuery, GetInvoiceLinesQueryVariables>(GetInvoiceLinesDocument, options);
        }
export function useGetInvoiceLinesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetInvoiceLinesQuery, GetInvoiceLinesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetInvoiceLinesQuery, GetInvoiceLinesQueryVariables>(GetInvoiceLinesDocument, options);
        }
export type GetInvoiceLinesQueryHookResult = ReturnType<typeof useGetInvoiceLinesQuery>;
export type GetInvoiceLinesLazyQueryHookResult = ReturnType<typeof useGetInvoiceLinesLazyQuery>;
export type GetInvoiceLinesSuspenseQueryHookResult = ReturnType<typeof useGetInvoiceLinesSuspenseQuery>;
export type GetInvoiceLinesQueryResult = Apollo.QueryResult<GetInvoiceLinesQuery, GetInvoiceLinesQueryVariables>;
export const ApproveInvoiceLineDocument = gql`
    mutation ApproveInvoiceLine($propertyName: String!, $socialNumber: String!, $approve: Boolean!, $invoiceNo: String!, $rejectReason: String) {
  approveInvoice(propertyName: $propertyName, socialNumber: $socialNumber, approve: $approve, rejectReason: $rejectReason, invoiceNo: $invoiceNo)
}
    `;
export type ApproveInvoiceLineMutationFn = Apollo.MutationFunction<ApproveInvoiceLineMutation, ApproveInvoiceLineMutationVariables>;

/**
 * __useApproveInvoiceLineMutation__
 *
 * To run a mutation, you first call `useApproveInvoiceLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveInvoiceLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveInvoiceLineMutation, { data, loading, error }] = useApproveInvoiceLineMutation({
 *   variables: {
 *      propertyName: // value for 'propertyName'
 *      socialNumber: // value for 'socialNumber'
 *      approve: // value for 'approve'
 *      invoiceNo: // value for 'invoiceNo'
 *      rejectReason: // value for 'rejectReason'
 *   },
 * });
 */
export function useApproveInvoiceLineMutation(baseOptions?: Apollo.MutationHookOptions<ApproveInvoiceLineMutation, ApproveInvoiceLineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveInvoiceLineMutation, ApproveInvoiceLineMutationVariables>(ApproveInvoiceLineDocument, options);
      }
export type ApproveInvoiceLineMutationHookResult = ReturnType<typeof useApproveInvoiceLineMutation>;
export type ApproveInvoiceLineMutationResult = Apollo.MutationResult<ApproveInvoiceLineMutation>;
export type ApproveInvoiceLineMutationOptions = Apollo.BaseMutationOptions<ApproveInvoiceLineMutation, ApproveInvoiceLineMutationVariables>;
export const GetAllowedPropertiesDocument = gql`
    query GetAllowedProperties {
  properties {
    address
    id
  }
  connectedOwners {
    userId
    socialNumber
    name
    email
    phone
    connectedToProperties {
      id
      address
      externalId
    }
  }
}
    `;

/**
 * __useGetAllowedPropertiesQuery__
 *
 * To run a query within a React component, call `useGetAllowedPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllowedPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllowedPropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllowedPropertiesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllowedPropertiesQuery, GetAllowedPropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllowedPropertiesQuery, GetAllowedPropertiesQueryVariables>(GetAllowedPropertiesDocument, options);
      }
export function useGetAllowedPropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllowedPropertiesQuery, GetAllowedPropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllowedPropertiesQuery, GetAllowedPropertiesQueryVariables>(GetAllowedPropertiesDocument, options);
        }
export function useGetAllowedPropertiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllowedPropertiesQuery, GetAllowedPropertiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllowedPropertiesQuery, GetAllowedPropertiesQueryVariables>(GetAllowedPropertiesDocument, options);
        }
export type GetAllowedPropertiesQueryHookResult = ReturnType<typeof useGetAllowedPropertiesQuery>;
export type GetAllowedPropertiesLazyQueryHookResult = ReturnType<typeof useGetAllowedPropertiesLazyQuery>;
export type GetAllowedPropertiesSuspenseQueryHookResult = ReturnType<typeof useGetAllowedPropertiesSuspenseQuery>;
export type GetAllowedPropertiesQueryResult = Apollo.QueryResult<GetAllowedPropertiesQuery, GetAllowedPropertiesQueryVariables>;
export const InvoicesByPropertyNameDocument = gql`
    query InvoicesByPropertyName($propertyName: String!, $take: Int!) {
  invoicesByPropertyName(propertyName: $propertyName, take: $take) {
    invoiceNo
    socialNumber
    amount
    dueDate
    remainingAmount
  }
}
    `;

/**
 * __useInvoicesByPropertyNameQuery__
 *
 * To run a query within a React component, call `useInvoicesByPropertyNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesByPropertyNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesByPropertyNameQuery({
 *   variables: {
 *      propertyName: // value for 'propertyName'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useInvoicesByPropertyNameQuery(baseOptions: Apollo.QueryHookOptions<InvoicesByPropertyNameQuery, InvoicesByPropertyNameQueryVariables> & ({ variables: InvoicesByPropertyNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesByPropertyNameQuery, InvoicesByPropertyNameQueryVariables>(InvoicesByPropertyNameDocument, options);
      }
export function useInvoicesByPropertyNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesByPropertyNameQuery, InvoicesByPropertyNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesByPropertyNameQuery, InvoicesByPropertyNameQueryVariables>(InvoicesByPropertyNameDocument, options);
        }
export function useInvoicesByPropertyNameSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InvoicesByPropertyNameQuery, InvoicesByPropertyNameQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InvoicesByPropertyNameQuery, InvoicesByPropertyNameQueryVariables>(InvoicesByPropertyNameDocument, options);
        }
export type InvoicesByPropertyNameQueryHookResult = ReturnType<typeof useInvoicesByPropertyNameQuery>;
export type InvoicesByPropertyNameLazyQueryHookResult = ReturnType<typeof useInvoicesByPropertyNameLazyQuery>;
export type InvoicesByPropertyNameSuspenseQueryHookResult = ReturnType<typeof useInvoicesByPropertyNameSuspenseQuery>;
export type InvoicesByPropertyNameQueryResult = Apollo.QueryResult<InvoicesByPropertyNameQuery, InvoicesByPropertyNameQueryVariables>;
export const GetPropertiesDocument = gql`
    query GetProperties {
  properties {
    id
    address
    externalId
    registrationId
    access
  }
}
    `;

/**
 * __useGetPropertiesQuery__
 *
 * To run a query within a React component, call `useGetPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPropertiesQuery(baseOptions?: Apollo.QueryHookOptions<GetPropertiesQuery, GetPropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPropertiesQuery, GetPropertiesQueryVariables>(GetPropertiesDocument, options);
      }
export function useGetPropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertiesQuery, GetPropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPropertiesQuery, GetPropertiesQueryVariables>(GetPropertiesDocument, options);
        }
export function useGetPropertiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPropertiesQuery, GetPropertiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPropertiesQuery, GetPropertiesQueryVariables>(GetPropertiesDocument, options);
        }
export type GetPropertiesQueryHookResult = ReturnType<typeof useGetPropertiesQuery>;
export type GetPropertiesLazyQueryHookResult = ReturnType<typeof useGetPropertiesLazyQuery>;
export type GetPropertiesSuspenseQueryHookResult = ReturnType<typeof useGetPropertiesSuspenseQuery>;
export type GetPropertiesQueryResult = Apollo.QueryResult<GetPropertiesQuery, GetPropertiesQueryVariables>;
export const GetPropertiesForDashboardDocument = gql`
    query GetPropertiesForDashboard {
  documentsV2(limit: 10, page: 0) {
    id
    name
    type
    mimetype
    description
    createdDate
    modifiedDate
    displayDate
    accessType
  }
  properties {
    id
    address
    externalId
    registrationId
    access
    contacts {
      id
      name
      phone
      email
      role
      propertyId
    }
    documentsCount
    ownersCount
    propertySupportRequests {
      id
      summary
      assignee
      reporter
      date
      description
      finished
    }
    informations {
      key
      title
      value
      createdAt
      modifiedAt
    }
  }
  ownerConnections {
    properties {
      id
      address
      externalId
    }
  }
}
    `;

/**
 * __useGetPropertiesForDashboardQuery__
 *
 * To run a query within a React component, call `useGetPropertiesForDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertiesForDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertiesForDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPropertiesForDashboardQuery(baseOptions?: Apollo.QueryHookOptions<GetPropertiesForDashboardQuery, GetPropertiesForDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPropertiesForDashboardQuery, GetPropertiesForDashboardQueryVariables>(GetPropertiesForDashboardDocument, options);
      }
export function useGetPropertiesForDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertiesForDashboardQuery, GetPropertiesForDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPropertiesForDashboardQuery, GetPropertiesForDashboardQueryVariables>(GetPropertiesForDashboardDocument, options);
        }
export function useGetPropertiesForDashboardSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPropertiesForDashboardQuery, GetPropertiesForDashboardQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPropertiesForDashboardQuery, GetPropertiesForDashboardQueryVariables>(GetPropertiesForDashboardDocument, options);
        }
export type GetPropertiesForDashboardQueryHookResult = ReturnType<typeof useGetPropertiesForDashboardQuery>;
export type GetPropertiesForDashboardLazyQueryHookResult = ReturnType<typeof useGetPropertiesForDashboardLazyQuery>;
export type GetPropertiesForDashboardSuspenseQueryHookResult = ReturnType<typeof useGetPropertiesForDashboardSuspenseQuery>;
export type GetPropertiesForDashboardQueryResult = Apollo.QueryResult<GetPropertiesForDashboardQuery, GetPropertiesForDashboardQueryVariables>;
export const GetPropertiesInvoicesForDashboardDocument = gql`
    query GetPropertiesInvoicesForDashboard {
  properties {
    id
    address
    invoiceApprovals {
      approvalNo
      approvalStatus
    }
    invoices {
      invoiceNo
      socialNumber
      amount
      dueDate
      remainingAmount
    }
  }
  ownerConnections {
    properties {
      id
      address
      externalId
      invoices(take: 10) {
        invoiceNo
        socialNumber
        amount
        dueDate
        remainingAmount
      }
    }
  }
}
    `;

/**
 * __useGetPropertiesInvoicesForDashboardQuery__
 *
 * To run a query within a React component, call `useGetPropertiesInvoicesForDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertiesInvoicesForDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertiesInvoicesForDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPropertiesInvoicesForDashboardQuery(baseOptions?: Apollo.QueryHookOptions<GetPropertiesInvoicesForDashboardQuery, GetPropertiesInvoicesForDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPropertiesInvoicesForDashboardQuery, GetPropertiesInvoicesForDashboardQueryVariables>(GetPropertiesInvoicesForDashboardDocument, options);
      }
export function useGetPropertiesInvoicesForDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertiesInvoicesForDashboardQuery, GetPropertiesInvoicesForDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPropertiesInvoicesForDashboardQuery, GetPropertiesInvoicesForDashboardQueryVariables>(GetPropertiesInvoicesForDashboardDocument, options);
        }
export function useGetPropertiesInvoicesForDashboardSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPropertiesInvoicesForDashboardQuery, GetPropertiesInvoicesForDashboardQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPropertiesInvoicesForDashboardQuery, GetPropertiesInvoicesForDashboardQueryVariables>(GetPropertiesInvoicesForDashboardDocument, options);
        }
export type GetPropertiesInvoicesForDashboardQueryHookResult = ReturnType<typeof useGetPropertiesInvoicesForDashboardQuery>;
export type GetPropertiesInvoicesForDashboardLazyQueryHookResult = ReturnType<typeof useGetPropertiesInvoicesForDashboardLazyQuery>;
export type GetPropertiesInvoicesForDashboardSuspenseQueryHookResult = ReturnType<typeof useGetPropertiesInvoicesForDashboardSuspenseQuery>;
export type GetPropertiesInvoicesForDashboardQueryResult = Apollo.QueryResult<GetPropertiesInvoicesForDashboardQuery, GetPropertiesInvoicesForDashboardQueryVariables>;
export const GetPropertyFinancialsDetailDocument = gql`
    query GetPropertyFinancialsDetail($propertyId: String!, $bankClaimsTake: Int, $bankClaimsSkip: Int, $bankClaimsDateFrom: String, $bankClaimsDateTo: String) {
  me {
    userId
    socialNumber
  }
  propertyById(id: $propertyId) {
    id
    address
    externalId
    registrationId
    access
    bankAccounts {
      bankAccountNo
      name
      contact
      id
      balanceAtDate
      bankAccountGroup
    }
    bankClaims(take: $bankClaimsTake, skip: $bankClaimsSkip, dateTo: $bankClaimsDateTo, dateFrom: $bankClaimsDateFrom) {
      no
      postingDate
      bankAccountNo
      description
      amount
      remainingAmount
      open
      entryType
    }
    invoiceApprovals {
      approvalNo
      creditInvoice
      invoiceToNo
      invoiceToName
      vendorNo
      vendorName
      date
      documentNo
      description
      reference
      deliveryDate
      totalAmount
      totalAmountWithVat
      lineTotalAmountWithVat
      approvalStatus
      approvalMessage
      approved
    }
  }
}
    `;

/**
 * __useGetPropertyFinancialsDetailQuery__
 *
 * To run a query within a React component, call `useGetPropertyFinancialsDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyFinancialsDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyFinancialsDetailQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      bankClaimsTake: // value for 'bankClaimsTake'
 *      bankClaimsSkip: // value for 'bankClaimsSkip'
 *      bankClaimsDateFrom: // value for 'bankClaimsDateFrom'
 *      bankClaimsDateTo: // value for 'bankClaimsDateTo'
 *   },
 * });
 */
export function useGetPropertyFinancialsDetailQuery(baseOptions: Apollo.QueryHookOptions<GetPropertyFinancialsDetailQuery, GetPropertyFinancialsDetailQueryVariables> & ({ variables: GetPropertyFinancialsDetailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPropertyFinancialsDetailQuery, GetPropertyFinancialsDetailQueryVariables>(GetPropertyFinancialsDetailDocument, options);
      }
export function useGetPropertyFinancialsDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyFinancialsDetailQuery, GetPropertyFinancialsDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPropertyFinancialsDetailQuery, GetPropertyFinancialsDetailQueryVariables>(GetPropertyFinancialsDetailDocument, options);
        }
export function useGetPropertyFinancialsDetailSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPropertyFinancialsDetailQuery, GetPropertyFinancialsDetailQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPropertyFinancialsDetailQuery, GetPropertyFinancialsDetailQueryVariables>(GetPropertyFinancialsDetailDocument, options);
        }
export type GetPropertyFinancialsDetailQueryHookResult = ReturnType<typeof useGetPropertyFinancialsDetailQuery>;
export type GetPropertyFinancialsDetailLazyQueryHookResult = ReturnType<typeof useGetPropertyFinancialsDetailLazyQuery>;
export type GetPropertyFinancialsDetailSuspenseQueryHookResult = ReturnType<typeof useGetPropertyFinancialsDetailSuspenseQuery>;
export type GetPropertyFinancialsDetailQueryResult = Apollo.QueryResult<GetPropertyFinancialsDetailQuery, GetPropertyFinancialsDetailQueryVariables>;
export const GetPropertyDetailDocument = gql`
    query GetPropertyDetail($propertyId: String!) {
  propertyById(id: $propertyId) {
    id
    address
    externalId
    registrationId
    access
    contacts {
      id
      name
      role
      phone
      email
      propertyId
    }
  }
}
    `;

/**
 * __useGetPropertyDetailQuery__
 *
 * To run a query within a React component, call `useGetPropertyDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyDetailQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetPropertyDetailQuery(baseOptions: Apollo.QueryHookOptions<GetPropertyDetailQuery, GetPropertyDetailQueryVariables> & ({ variables: GetPropertyDetailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPropertyDetailQuery, GetPropertyDetailQueryVariables>(GetPropertyDetailDocument, options);
      }
export function useGetPropertyDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyDetailQuery, GetPropertyDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPropertyDetailQuery, GetPropertyDetailQueryVariables>(GetPropertyDetailDocument, options);
        }
export function useGetPropertyDetailSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPropertyDetailQuery, GetPropertyDetailQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPropertyDetailQuery, GetPropertyDetailQueryVariables>(GetPropertyDetailDocument, options);
        }
export type GetPropertyDetailQueryHookResult = ReturnType<typeof useGetPropertyDetailQuery>;
export type GetPropertyDetailLazyQueryHookResult = ReturnType<typeof useGetPropertyDetailLazyQuery>;
export type GetPropertyDetailSuspenseQueryHookResult = ReturnType<typeof useGetPropertyDetailSuspenseQuery>;
export type GetPropertyDetailQueryResult = Apollo.QueryResult<GetPropertyDetailQuery, GetPropertyDetailQueryVariables>;
export const AssignOwnerToPropertyDocument = gql`
    mutation AssignOwnerToProperty($propertyId: String!, $ownerId: String!) {
  assignOwnerToProperty(propertyId: $propertyId, ownerId: $ownerId)
}
    `;
export type AssignOwnerToPropertyMutationFn = Apollo.MutationFunction<AssignOwnerToPropertyMutation, AssignOwnerToPropertyMutationVariables>;

/**
 * __useAssignOwnerToPropertyMutation__
 *
 * To run a mutation, you first call `useAssignOwnerToPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignOwnerToPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignOwnerToPropertyMutation, { data, loading, error }] = useAssignOwnerToPropertyMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useAssignOwnerToPropertyMutation(baseOptions?: Apollo.MutationHookOptions<AssignOwnerToPropertyMutation, AssignOwnerToPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignOwnerToPropertyMutation, AssignOwnerToPropertyMutationVariables>(AssignOwnerToPropertyDocument, options);
      }
export type AssignOwnerToPropertyMutationHookResult = ReturnType<typeof useAssignOwnerToPropertyMutation>;
export type AssignOwnerToPropertyMutationResult = Apollo.MutationResult<AssignOwnerToPropertyMutation>;
export type AssignOwnerToPropertyMutationOptions = Apollo.BaseMutationOptions<AssignOwnerToPropertyMutation, AssignOwnerToPropertyMutationVariables>;
export const AssignConnectedOwnerToPropertyDocument = gql`
    mutation AssignConnectedOwnerToProperty($propertyId: String!, $connectedOwnerId: String!) {
  assignConnectedOwnerToProperty(propertyId: $propertyId, connectedOwnerId: $connectedOwnerId)
}
    `;
export type AssignConnectedOwnerToPropertyMutationFn = Apollo.MutationFunction<AssignConnectedOwnerToPropertyMutation, AssignConnectedOwnerToPropertyMutationVariables>;

/**
 * __useAssignConnectedOwnerToPropertyMutation__
 *
 * To run a mutation, you first call `useAssignConnectedOwnerToPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignConnectedOwnerToPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignConnectedOwnerToPropertyMutation, { data, loading, error }] = useAssignConnectedOwnerToPropertyMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      connectedOwnerId: // value for 'connectedOwnerId'
 *   },
 * });
 */
export function useAssignConnectedOwnerToPropertyMutation(baseOptions?: Apollo.MutationHookOptions<AssignConnectedOwnerToPropertyMutation, AssignConnectedOwnerToPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignConnectedOwnerToPropertyMutation, AssignConnectedOwnerToPropertyMutationVariables>(AssignConnectedOwnerToPropertyDocument, options);
      }
export type AssignConnectedOwnerToPropertyMutationHookResult = ReturnType<typeof useAssignConnectedOwnerToPropertyMutation>;
export type AssignConnectedOwnerToPropertyMutationResult = Apollo.MutationResult<AssignConnectedOwnerToPropertyMutation>;
export type AssignConnectedOwnerToPropertyMutationOptions = Apollo.BaseMutationOptions<AssignConnectedOwnerToPropertyMutation, AssignConnectedOwnerToPropertyMutationVariables>;
export const UnassignOwnerFromPropertyDocument = gql`
    mutation UnassignOwnerFromProperty($propertyId: String!, $ownerId: String!) {
  unassignOwnerFromProperty(propertyId: $propertyId, ownerId: $ownerId)
}
    `;
export type UnassignOwnerFromPropertyMutationFn = Apollo.MutationFunction<UnassignOwnerFromPropertyMutation, UnassignOwnerFromPropertyMutationVariables>;

/**
 * __useUnassignOwnerFromPropertyMutation__
 *
 * To run a mutation, you first call `useUnassignOwnerFromPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignOwnerFromPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignOwnerFromPropertyMutation, { data, loading, error }] = useUnassignOwnerFromPropertyMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useUnassignOwnerFromPropertyMutation(baseOptions?: Apollo.MutationHookOptions<UnassignOwnerFromPropertyMutation, UnassignOwnerFromPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassignOwnerFromPropertyMutation, UnassignOwnerFromPropertyMutationVariables>(UnassignOwnerFromPropertyDocument, options);
      }
export type UnassignOwnerFromPropertyMutationHookResult = ReturnType<typeof useUnassignOwnerFromPropertyMutation>;
export type UnassignOwnerFromPropertyMutationResult = Apollo.MutationResult<UnassignOwnerFromPropertyMutation>;
export type UnassignOwnerFromPropertyMutationOptions = Apollo.BaseMutationOptions<UnassignOwnerFromPropertyMutation, UnassignOwnerFromPropertyMutationVariables>;
export const UnassignConnectedOwnerFromPropertyDocument = gql`
    mutation UnassignConnectedOwnerFromProperty($propertyId: String!, $connectedOwnerId: String!) {
  unassignConnectedOwnerFromProperty(propertyId: $propertyId, connectedOwnerId: $connectedOwnerId)
}
    `;
export type UnassignConnectedOwnerFromPropertyMutationFn = Apollo.MutationFunction<UnassignConnectedOwnerFromPropertyMutation, UnassignConnectedOwnerFromPropertyMutationVariables>;

/**
 * __useUnassignConnectedOwnerFromPropertyMutation__
 *
 * To run a mutation, you first call `useUnassignConnectedOwnerFromPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignConnectedOwnerFromPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignConnectedOwnerFromPropertyMutation, { data, loading, error }] = useUnassignConnectedOwnerFromPropertyMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      connectedOwnerId: // value for 'connectedOwnerId'
 *   },
 * });
 */
export function useUnassignConnectedOwnerFromPropertyMutation(baseOptions?: Apollo.MutationHookOptions<UnassignConnectedOwnerFromPropertyMutation, UnassignConnectedOwnerFromPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassignConnectedOwnerFromPropertyMutation, UnassignConnectedOwnerFromPropertyMutationVariables>(UnassignConnectedOwnerFromPropertyDocument, options);
      }
export type UnassignConnectedOwnerFromPropertyMutationHookResult = ReturnType<typeof useUnassignConnectedOwnerFromPropertyMutation>;
export type UnassignConnectedOwnerFromPropertyMutationResult = Apollo.MutationResult<UnassignConnectedOwnerFromPropertyMutation>;
export type UnassignConnectedOwnerFromPropertyMutationOptions = Apollo.BaseMutationOptions<UnassignConnectedOwnerFromPropertyMutation, UnassignConnectedOwnerFromPropertyMutationVariables>;
export const CreateAndAssignOwnerToPropertyDocument = gql`
    mutation CreateAndAssignOwnerToProperty($owner: OwnerAssignmentInput!, $propertyId: String!) {
  createAndAssignOwnerToProperty(propertyId: $propertyId, owner: $owner) {
    success
    ownerId
  }
}
    `;
export type CreateAndAssignOwnerToPropertyMutationFn = Apollo.MutationFunction<CreateAndAssignOwnerToPropertyMutation, CreateAndAssignOwnerToPropertyMutationVariables>;

/**
 * __useCreateAndAssignOwnerToPropertyMutation__
 *
 * To run a mutation, you first call `useCreateAndAssignOwnerToPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAndAssignOwnerToPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAndAssignOwnerToPropertyMutation, { data, loading, error }] = useCreateAndAssignOwnerToPropertyMutation({
 *   variables: {
 *      owner: // value for 'owner'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useCreateAndAssignOwnerToPropertyMutation(baseOptions?: Apollo.MutationHookOptions<CreateAndAssignOwnerToPropertyMutation, CreateAndAssignOwnerToPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAndAssignOwnerToPropertyMutation, CreateAndAssignOwnerToPropertyMutationVariables>(CreateAndAssignOwnerToPropertyDocument, options);
      }
export type CreateAndAssignOwnerToPropertyMutationHookResult = ReturnType<typeof useCreateAndAssignOwnerToPropertyMutation>;
export type CreateAndAssignOwnerToPropertyMutationResult = Apollo.MutationResult<CreateAndAssignOwnerToPropertyMutation>;
export type CreateAndAssignOwnerToPropertyMutationOptions = Apollo.BaseMutationOptions<CreateAndAssignOwnerToPropertyMutation, CreateAndAssignOwnerToPropertyMutationVariables>;
export const GetLoggedInUserDocument = gql`
    query GetLoggedInUser {
  me {
    userId
    socialNumber
    name
    email
    phone
    userType
    session {
      expiration
    }
  }
}
    `;

/**
 * __useGetLoggedInUserQuery__
 *
 * To run a query within a React component, call `useGetLoggedInUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoggedInUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoggedInUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoggedInUserQuery(baseOptions?: Apollo.QueryHookOptions<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(GetLoggedInUserDocument, options);
      }
export function useGetLoggedInUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(GetLoggedInUserDocument, options);
        }
export function useGetLoggedInUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(GetLoggedInUserDocument, options);
        }
export type GetLoggedInUserQueryHookResult = ReturnType<typeof useGetLoggedInUserQuery>;
export type GetLoggedInUserLazyQueryHookResult = ReturnType<typeof useGetLoggedInUserLazyQuery>;
export type GetLoggedInUserSuspenseQueryHookResult = ReturnType<typeof useGetLoggedInUserSuspenseQuery>;
export type GetLoggedInUserQueryResult = Apollo.QueryResult<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>;
export const UpdateOwnerDocument = gql`
    mutation UpdateOwner($owner: OwnerUpdateInput!) {
  updateOwner(owner: $owner)
}
    `;
export type UpdateOwnerMutationFn = Apollo.MutationFunction<UpdateOwnerMutation, UpdateOwnerMutationVariables>;

/**
 * __useUpdateOwnerMutation__
 *
 * To run a mutation, you first call `useUpdateOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnerMutation, { data, loading, error }] = useUpdateOwnerMutation({
 *   variables: {
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useUpdateOwnerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOwnerMutation, UpdateOwnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOwnerMutation, UpdateOwnerMutationVariables>(UpdateOwnerDocument, options);
      }
export type UpdateOwnerMutationHookResult = ReturnType<typeof useUpdateOwnerMutation>;
export type UpdateOwnerMutationResult = Apollo.MutationResult<UpdateOwnerMutation>;
export type UpdateOwnerMutationOptions = Apollo.BaseMutationOptions<UpdateOwnerMutation, UpdateOwnerMutationVariables>;
export const SendRequestNotificationDocument = gql`
    mutation SendRequestNotification($input: RequestAccessNotificationInput!) {
  sendRequestAccessNotification(requestInput: $input)
}
    `;
export type SendRequestNotificationMutationFn = Apollo.MutationFunction<SendRequestNotificationMutation, SendRequestNotificationMutationVariables>;

/**
 * __useSendRequestNotificationMutation__
 *
 * To run a mutation, you first call `useSendRequestNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRequestNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRequestNotificationMutation, { data, loading, error }] = useSendRequestNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendRequestNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendRequestNotificationMutation, SendRequestNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendRequestNotificationMutation, SendRequestNotificationMutationVariables>(SendRequestNotificationDocument, options);
      }
export type SendRequestNotificationMutationHookResult = ReturnType<typeof useSendRequestNotificationMutation>;
export type SendRequestNotificationMutationResult = Apollo.MutationResult<SendRequestNotificationMutation>;
export type SendRequestNotificationMutationOptions = Apollo.BaseMutationOptions<SendRequestNotificationMutation, SendRequestNotificationMutationVariables>;
export const GetOwnerCardsByPropertyDocument = gql`
    query GetOwnerCardsByProperty($propertyId: String!) {
  getOwnerCardsByProperty(propertyId: $propertyId) {
    id
    name
    socialNumber
  }
  propertyById(id: $propertyId) {
    address
    access
  }
}
    `;

/**
 * __useGetOwnerCardsByPropertyQuery__
 *
 * To run a query within a React component, call `useGetOwnerCardsByPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerCardsByPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerCardsByPropertyQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetOwnerCardsByPropertyQuery(baseOptions: Apollo.QueryHookOptions<GetOwnerCardsByPropertyQuery, GetOwnerCardsByPropertyQueryVariables> & ({ variables: GetOwnerCardsByPropertyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOwnerCardsByPropertyQuery, GetOwnerCardsByPropertyQueryVariables>(GetOwnerCardsByPropertyDocument, options);
      }
export function useGetOwnerCardsByPropertyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOwnerCardsByPropertyQuery, GetOwnerCardsByPropertyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOwnerCardsByPropertyQuery, GetOwnerCardsByPropertyQueryVariables>(GetOwnerCardsByPropertyDocument, options);
        }
export function useGetOwnerCardsByPropertySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOwnerCardsByPropertyQuery, GetOwnerCardsByPropertyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOwnerCardsByPropertyQuery, GetOwnerCardsByPropertyQueryVariables>(GetOwnerCardsByPropertyDocument, options);
        }
export type GetOwnerCardsByPropertyQueryHookResult = ReturnType<typeof useGetOwnerCardsByPropertyQuery>;
export type GetOwnerCardsByPropertyLazyQueryHookResult = ReturnType<typeof useGetOwnerCardsByPropertyLazyQuery>;
export type GetOwnerCardsByPropertySuspenseQueryHookResult = ReturnType<typeof useGetOwnerCardsByPropertySuspenseQuery>;
export type GetOwnerCardsByPropertyQueryResult = Apollo.QueryResult<GetOwnerCardsByPropertyQuery, GetOwnerCardsByPropertyQueryVariables>;
export const GetContactsForPropertyDocument = gql`
    query GetContactsForProperty($propertyId: String!) {
  propertyById(id: $propertyId) {
    address
    contacts {
      id
      name
      role
      email
      phone
      propertyId
    }
  }
}
    `;

/**
 * __useGetContactsForPropertyQuery__
 *
 * To run a query within a React component, call `useGetContactsForPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsForPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsForPropertyQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetContactsForPropertyQuery(baseOptions: Apollo.QueryHookOptions<GetContactsForPropertyQuery, GetContactsForPropertyQueryVariables> & ({ variables: GetContactsForPropertyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactsForPropertyQuery, GetContactsForPropertyQueryVariables>(GetContactsForPropertyDocument, options);
      }
export function useGetContactsForPropertyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactsForPropertyQuery, GetContactsForPropertyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactsForPropertyQuery, GetContactsForPropertyQueryVariables>(GetContactsForPropertyDocument, options);
        }
export function useGetContactsForPropertySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetContactsForPropertyQuery, GetContactsForPropertyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetContactsForPropertyQuery, GetContactsForPropertyQueryVariables>(GetContactsForPropertyDocument, options);
        }
export type GetContactsForPropertyQueryHookResult = ReturnType<typeof useGetContactsForPropertyQuery>;
export type GetContactsForPropertyLazyQueryHookResult = ReturnType<typeof useGetContactsForPropertyLazyQuery>;
export type GetContactsForPropertySuspenseQueryHookResult = ReturnType<typeof useGetContactsForPropertySuspenseQuery>;
export type GetContactsForPropertyQueryResult = Apollo.QueryResult<GetContactsForPropertyQuery, GetContactsForPropertyQueryVariables>;
export const DeleteContactsFromPropertyDocument = gql`
    mutation DeleteContactsFromProperty($propertyId: String!, $propertyContactId: String!) {
  deletePropertyContact(propertyId: $propertyId, propertyContactId: $propertyContactId)
}
    `;
export type DeleteContactsFromPropertyMutationFn = Apollo.MutationFunction<DeleteContactsFromPropertyMutation, DeleteContactsFromPropertyMutationVariables>;

/**
 * __useDeleteContactsFromPropertyMutation__
 *
 * To run a mutation, you first call `useDeleteContactsFromPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactsFromPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactsFromPropertyMutation, { data, loading, error }] = useDeleteContactsFromPropertyMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      propertyContactId: // value for 'propertyContactId'
 *   },
 * });
 */
export function useDeleteContactsFromPropertyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactsFromPropertyMutation, DeleteContactsFromPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactsFromPropertyMutation, DeleteContactsFromPropertyMutationVariables>(DeleteContactsFromPropertyDocument, options);
      }
export type DeleteContactsFromPropertyMutationHookResult = ReturnType<typeof useDeleteContactsFromPropertyMutation>;
export type DeleteContactsFromPropertyMutationResult = Apollo.MutationResult<DeleteContactsFromPropertyMutation>;
export type DeleteContactsFromPropertyMutationOptions = Apollo.BaseMutationOptions<DeleteContactsFromPropertyMutation, DeleteContactsFromPropertyMutationVariables>;
export const UpdateContactsForPropertyDocument = gql`
    mutation UpdateContactsForProperty($propertyContactId: String!, $propertyContact: PropertyContactInput!) {
  updatePropertyContact(propertyContactId: $propertyContactId, propertyContact: $propertyContact) {
    id
    name
    role
    phone
    email
    propertyId
  }
}
    `;
export type UpdateContactsForPropertyMutationFn = Apollo.MutationFunction<UpdateContactsForPropertyMutation, UpdateContactsForPropertyMutationVariables>;

/**
 * __useUpdateContactsForPropertyMutation__
 *
 * To run a mutation, you first call `useUpdateContactsForPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactsForPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactsForPropertyMutation, { data, loading, error }] = useUpdateContactsForPropertyMutation({
 *   variables: {
 *      propertyContactId: // value for 'propertyContactId'
 *      propertyContact: // value for 'propertyContact'
 *   },
 * });
 */
export function useUpdateContactsForPropertyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactsForPropertyMutation, UpdateContactsForPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactsForPropertyMutation, UpdateContactsForPropertyMutationVariables>(UpdateContactsForPropertyDocument, options);
      }
export type UpdateContactsForPropertyMutationHookResult = ReturnType<typeof useUpdateContactsForPropertyMutation>;
export type UpdateContactsForPropertyMutationResult = Apollo.MutationResult<UpdateContactsForPropertyMutation>;
export type UpdateContactsForPropertyMutationOptions = Apollo.BaseMutationOptions<UpdateContactsForPropertyMutation, UpdateContactsForPropertyMutationVariables>;
export const CreateContactsForPropertyDocument = gql`
    mutation CreateContactsForProperty($propertyContact: PropertyContactInput!) {
  createPropertyContact(propertyContact: $propertyContact) {
    id
    name
    role
    phone
    email
    propertyId
  }
}
    `;
export type CreateContactsForPropertyMutationFn = Apollo.MutationFunction<CreateContactsForPropertyMutation, CreateContactsForPropertyMutationVariables>;

/**
 * __useCreateContactsForPropertyMutation__
 *
 * To run a mutation, you first call `useCreateContactsForPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactsForPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactsForPropertyMutation, { data, loading, error }] = useCreateContactsForPropertyMutation({
 *   variables: {
 *      propertyContact: // value for 'propertyContact'
 *   },
 * });
 */
export function useCreateContactsForPropertyMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactsForPropertyMutation, CreateContactsForPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactsForPropertyMutation, CreateContactsForPropertyMutationVariables>(CreateContactsForPropertyDocument, options);
      }
export type CreateContactsForPropertyMutationHookResult = ReturnType<typeof useCreateContactsForPropertyMutation>;
export type CreateContactsForPropertyMutationResult = Apollo.MutationResult<CreateContactsForPropertyMutation>;
export type CreateContactsForPropertyMutationOptions = Apollo.BaseMutationOptions<CreateContactsForPropertyMutation, CreateContactsForPropertyMutationVariables>;
export const GetOwnerCardBySocialNumberDocument = gql`
    query GetOwnerCardBySocialNumber($socialNumber: String!) {
  getOwnerCardBySocialNumber(socialNumber: $socialNumber) {
    id
    name
    socialNumber
  }
}
    `;

/**
 * __useGetOwnerCardBySocialNumberQuery__
 *
 * To run a query within a React component, call `useGetOwnerCardBySocialNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerCardBySocialNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerCardBySocialNumberQuery({
 *   variables: {
 *      socialNumber: // value for 'socialNumber'
 *   },
 * });
 */
export function useGetOwnerCardBySocialNumberQuery(baseOptions: Apollo.QueryHookOptions<GetOwnerCardBySocialNumberQuery, GetOwnerCardBySocialNumberQueryVariables> & ({ variables: GetOwnerCardBySocialNumberQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOwnerCardBySocialNumberQuery, GetOwnerCardBySocialNumberQueryVariables>(GetOwnerCardBySocialNumberDocument, options);
      }
export function useGetOwnerCardBySocialNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOwnerCardBySocialNumberQuery, GetOwnerCardBySocialNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOwnerCardBySocialNumberQuery, GetOwnerCardBySocialNumberQueryVariables>(GetOwnerCardBySocialNumberDocument, options);
        }
export function useGetOwnerCardBySocialNumberSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOwnerCardBySocialNumberQuery, GetOwnerCardBySocialNumberQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOwnerCardBySocialNumberQuery, GetOwnerCardBySocialNumberQueryVariables>(GetOwnerCardBySocialNumberDocument, options);
        }
export type GetOwnerCardBySocialNumberQueryHookResult = ReturnType<typeof useGetOwnerCardBySocialNumberQuery>;
export type GetOwnerCardBySocialNumberLazyQueryHookResult = ReturnType<typeof useGetOwnerCardBySocialNumberLazyQuery>;
export type GetOwnerCardBySocialNumberSuspenseQueryHookResult = ReturnType<typeof useGetOwnerCardBySocialNumberSuspenseQuery>;
export type GetOwnerCardBySocialNumberQueryResult = Apollo.QueryResult<GetOwnerCardBySocialNumberQuery, GetOwnerCardBySocialNumberQueryVariables>;
export const DisconnectOwnerDocument = gql`
    mutation DisconnectOwner($id: String!) {
  disconnectConnectedOwner(ownerId: $id)
}
    `;
export type DisconnectOwnerMutationFn = Apollo.MutationFunction<DisconnectOwnerMutation, DisconnectOwnerMutationVariables>;

/**
 * __useDisconnectOwnerMutation__
 *
 * To run a mutation, you first call `useDisconnectOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectOwnerMutation, { data, loading, error }] = useDisconnectOwnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisconnectOwnerMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectOwnerMutation, DisconnectOwnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisconnectOwnerMutation, DisconnectOwnerMutationVariables>(DisconnectOwnerDocument, options);
      }
export type DisconnectOwnerMutationHookResult = ReturnType<typeof useDisconnectOwnerMutation>;
export type DisconnectOwnerMutationResult = Apollo.MutationResult<DisconnectOwnerMutation>;
export type DisconnectOwnerMutationOptions = Apollo.BaseMutationOptions<DisconnectOwnerMutation, DisconnectOwnerMutationVariables>;
export const ConnectOwnerDocument = gql`
    mutation ConnectOwner($id: String!, $reason: String) {
  connectOwner(ownerId: $id, reason: $reason)
}
    `;
export type ConnectOwnerMutationFn = Apollo.MutationFunction<ConnectOwnerMutation, ConnectOwnerMutationVariables>;

/**
 * __useConnectOwnerMutation__
 *
 * To run a mutation, you first call `useConnectOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectOwnerMutation, { data, loading, error }] = useConnectOwnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useConnectOwnerMutation(baseOptions?: Apollo.MutationHookOptions<ConnectOwnerMutation, ConnectOwnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectOwnerMutation, ConnectOwnerMutationVariables>(ConnectOwnerDocument, options);
      }
export type ConnectOwnerMutationHookResult = ReturnType<typeof useConnectOwnerMutation>;
export type ConnectOwnerMutationResult = Apollo.MutationResult<ConnectOwnerMutation>;
export type ConnectOwnerMutationOptions = Apollo.BaseMutationOptions<ConnectOwnerMutation, ConnectOwnerMutationVariables>;
export const CreateAndConnectOwnerDocument = gql`
    mutation CreateAndConnectOwner($input: OwnerAssignmentInput!, $reason: String) {
  createAndConnectOwner(owner: $input, reason: $reason) {
    success
    ownerId
  }
}
    `;
export type CreateAndConnectOwnerMutationFn = Apollo.MutationFunction<CreateAndConnectOwnerMutation, CreateAndConnectOwnerMutationVariables>;

/**
 * __useCreateAndConnectOwnerMutation__
 *
 * To run a mutation, you first call `useCreateAndConnectOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAndConnectOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAndConnectOwnerMutation, { data, loading, error }] = useCreateAndConnectOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCreateAndConnectOwnerMutation(baseOptions?: Apollo.MutationHookOptions<CreateAndConnectOwnerMutation, CreateAndConnectOwnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAndConnectOwnerMutation, CreateAndConnectOwnerMutationVariables>(CreateAndConnectOwnerDocument, options);
      }
export type CreateAndConnectOwnerMutationHookResult = ReturnType<typeof useCreateAndConnectOwnerMutation>;
export type CreateAndConnectOwnerMutationResult = Apollo.MutationResult<CreateAndConnectOwnerMutation>;
export type CreateAndConnectOwnerMutationOptions = Apollo.BaseMutationOptions<CreateAndConnectOwnerMutation, CreateAndConnectOwnerMutationVariables>;