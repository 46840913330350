import React from "react";
import styled from "styled-components";

const TooltipWrapper = styled.div`
  .tooltip {
    position: relative;
  }

  /* Tooltip box */
  .tooltip:before {
    content: attr(data-tooltip);
    position: absolute;
    width: 100px;
    background-color: #062b45;
    color: #fff;
    text-align: center;
    padding: 10px;
    line-height: 1.2;
    border-radius: 6px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.6s;
    bottom: 125%;
    left: 50%;
    margin-left: -80px;
    font-size: 0.75em;
    visibility: hidden;
  }

  /* Tooltip arrow */
  .tooltip:after {
    content: "";
    position: absolute;
    bottom: 75%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    opacity: 0;
    transition: opacity 0.6s;
    border-color: #062b45 transparent transparent transparent;
    visibility: hidden;
  }

  .tooltip:hover:before,
  .tooltip:hover:after {
    opacity: 1;
    visibility: visible;
  }

  .tooltip_inline {
    display: inline;
  }
`;

type TooltipProps = {
  className?: string;
  tooltipMessage: string;
  children: React.ReactNode;
};

export const Tooltip: React.FC<TooltipProps> = ({
  className,
  children,
  tooltipMessage,
}) => (
  <TooltipWrapper className={`${className}`}>
    <span className={`tooltip`} data-tooltip={tooltipMessage}>
      {children}
    </span>
  </TooltipWrapper>
);
