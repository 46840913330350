import React from "react";
import styled from "styled-components";
import { Bank } from "styled-icons/boxicons-solid";
import { BankAccount } from "../../../types";
import {
  PropertyInfoFlexItem,
  PropertyInfoName,
  PropertyInfoRole,
  PropertyInfoBankAmount,
} from "../layout/CardCommon";
import { formatAmount } from "../utils/amount";

const Container = styled.div`
  word-wrap: break-word;
  white-space: normal;
  padding: 15px;
  width: 100%;
`;

const InnerCard = styled.div`
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  border-bottom: 2px solid #dedede;
  background-color: #ffffff;
  padding: 15px;
`;

const MoneyIcon = styled(Bank as any)`
  color: #656464;
  height: 20px;
  align-self: flex-start;
  margin-right: 8px;
  padding-bottom: 4px;
`;

const Title = styled.h3`
  text-transform: uppercase;
  font-size: 16px;
  color: #656464;
  padding-top: 4px;
  margin-top: 0px;
  z-index: 1;
  position: relative;
  :before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    content: attr(title);
    background-color: #fff;
    color: #fff;
    z-index: -1;
    padding-right: 0.5em;
  }
  ::after {
    position: absolute;
    content: "";
    display: block;
    left: 0;
    right: 0;
    top: 50%;
    background-color: #e6e6e6;
    height: 1px;
    z-index: -2;
  }
`;

type PropertyBankAccountsCardProps = {
  bankAccounts: BankAccount[] | undefined;
};
export const PropertyBankAccountsCard: React.SFC<
  PropertyBankAccountsCardProps
> = (props) => {
  return (
    <Container>
      <InnerCard>
        <Title title="Bankareikningar........">
          <MoneyIcon />
          Bankareikningar
        </Title>
        {props.bankAccounts && props.bankAccounts.length > 0 ? (
          props.bankAccounts.map((account, idx) => {
            return (
              <PropertyInfoFlexItem key={idx}>
                <div>
                  <PropertyInfoName>
                    Reikningur ({account.bankAccountGroup})<br />
                  </PropertyInfoName>
                  <PropertyInfoRole> {account.name}</PropertyInfoRole>
                </div>
                <PropertyInfoBankAmount amount={account.balanceAtDate}>
                  {formatAmount(account.balanceAtDate) + " kr."}
                </PropertyInfoBankAmount>
              </PropertyInfoFlexItem>
            );
          })
        ) : (
          <div>Engin reikningur fannst fyrir húsfélagið.</div>
        )}
      </InnerCard>
    </Container>
  );
};
