import React, { useState } from "react";
import styled from "styled-components";
import { FileInvoiceDollar } from "styled-icons/fa-solid";
import { Edit } from "styled-icons/evaicons-solid";
import { InvoiceApproval } from "../../../types";
import {
  Table,
  TableHeader,
  TableTr,
  TableTh,
  TableBody,
} from "../layout/Table";
import { parseISO, format } from "date-fns";
import isLocale from "date-fns/locale/is";
import { formatAmount } from "../utils/amount";
import { ApproveInvoiceModal } from "../modals/ApproveInvoiceModal";
import ReactGA from "react-ga";

const Container = styled.div`
  word-wrap: break-word;
  white-space: normal;
  padding: 15px;
  width: 100%;
`;

const InnerCard = styled.div`
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  border-bottom: 2px solid #dedede;
  background-color: #ffffff;
  padding: 15px;
`;

const InvoiceIcon = styled(FileInvoiceDollar)`
  color: #656464;
  height: 20px;
  align-self: flex-start;
  margin-right: 8px;
  padding-bottom: 4px;
`;

const EditIcon = styled(Edit)`
  color: #656464;
  height: 20px;
  align-self: flex-start;
  margin-right: 8px;
  padding-bottom: 4px;
`;

type StatusProps = {
  approved: boolean;
};
const StatusText = styled.span<StatusProps>`
  font-weight: 500;
  color: ${(props) => (props.approved ? `#147414` : `#741414`)};
`;

const Title = styled.h3`
  text-transform: uppercase;
  font-size: 16px;
  color: #656464;
  padding-top: 4px;
  margin-top: 0px;
  z-index: 1;
  position: relative;
  :before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    content: attr(title);
    background-color: #fff;
    color: #fff;
    z-index: -1;
    padding-right: 0.5em;
  }
  ::after {
    position: absolute;
    content: "";
    display: block;
    left: 0;
    right: 0;
    top: 50%;
    background-color: #e6e6e6;
    height: 1px;
    z-index: -2;
  }
`;

const EditableStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    flex: 1 1 auto;
    background-color: #fff;
    position: relative;
    text-align: center;
    border: 0;
    line-height: 40px;
  }

  button + button {
    border-left: solid 1px white;
  }
`;

type PropertyInvoiceApprovalCardProps = {
  invoiceApprovals: InvoiceApproval[];
  loading?: boolean;
  propertyName: string;
  socialNumber: string;
  refetch: () => void;
};
export const PropertyInvoiceApprovalCard: React.FC<
  PropertyInvoiceApprovalCardProps
> = (props) => {
  const [showApprovalModal, setShowApprovalModal] = useState<{
    open: boolean;
    approval: InvoiceApproval;
    propertyName: string;
  }>();
  return (
    <Container>
      {(showApprovalModal?.open || false) && (
        <ApproveInvoiceModal
          open={showApprovalModal?.open || false}
          onDone={() => {
            ReactGA.event({
              category: "Invoice Approval",
              action: "Invoice approval saved",
            });
            setShowApprovalModal(undefined);
            props.refetch();
          }}
          approval={showApprovalModal?.approval || []}
          propertyName={showApprovalModal?.propertyName || ""}
          socialNumber={props.socialNumber}
        />
      )}
      <InnerCard>
        <Title title="Samþykktir á reikningum........">
          <InvoiceIcon />
          Samþykktir á reikningum
        </Title>

        {props.invoiceApprovals && props.invoiceApprovals.length > 0 ? (
          <>
            <Table>
              <TableHeader>
                <TableTr key="header-invoice">
                  <TableTh className="show-mobile">Upplýsingar</TableTh>
                  <TableTh className="hide-mobile">Númer</TableTh>
                  <TableTh className="hide-mobile">Fyrirtæki</TableTh>
                  <TableTh className="hide-mobile">Upphæð</TableTh>
                  <TableTh className="hide-mobile">Dagsetning</TableTh>
                  <TableTh className="hide-mobile">Útskýring</TableTh>
                  <TableTh>Staða</TableTh>
                </TableTr>
              </TableHeader>
              <TableBody>
                {props.invoiceApprovals.map((invoice, index) => {
                  const postingDate = parseISO(invoice.date);
                  return (
                    <TableTr key={index}>
                      <TableTh className="show-mobile">
                        <p>
                          <b>Fyrirtæki</b>: {invoice.vendorName} (
                          {invoice.vendorNo})
                        </p>{" "}
                        <p>
                          <b>Upphæð</b>:{" "}
                          {formatAmount(invoice.lineTotalAmountWithVat)} kr.
                        </p>
                        <p>
                          <b>Upplýsingar</b>: {invoice.description}
                        </p>
                      </TableTh>
                      <TableTh className="hide-mobile">
                        {invoice.approvalNo}
                      </TableTh>
                      <TableTh className="hide-mobile">
                        {invoice.vendorName} ({invoice.vendorNo})
                      </TableTh>
                      <TableTh className="hide-mobile">
                        {formatAmount(invoice.lineTotalAmountWithVat)} kr.
                      </TableTh>
                      <TableTh className="hide-mobile">
                        {format(postingDate, "d MMMM yyyy", {
                          locale: isLocale,
                        })}
                      </TableTh>
                      <TableTh className="hide-mobile">
                        {invoice.description}
                      </TableTh>
                      <TableTh>
                        <EditableStatus>
                          <StatusText approved={invoice.approved}>
                            {invoice.approvalStatus}
                          </StatusText>
                          <button
                            onClick={() => {
                              setShowApprovalModal({
                                propertyName: props.propertyName,
                                open: true,
                                approval: invoice,
                              });
                            }}
                          >
                            <EditIcon />
                          </button>
                        </EditableStatus>
                      </TableTh>
                    </TableTr>
                  );
                })}
              </TableBody>
            </Table>
          </>
        ) : (
          <div>Enginn óbókaður reikningur til staðar.</div>
        )}
      </InnerCard>
    </Container>
  );
};
