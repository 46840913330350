import { gql } from "@apollo/client";

export const getApprovalInvoiceWithCode = gql`
  query GetApprovalInvoice($id: String!, $code: String!) {
    approvalInvoice(id: $id, code: $code) {
      id
      name
      description
      mimetype
      type
      createDate
      modifiedDate
      propertyId
      amount
      origin
      dueDate
      status
      property {
        id
        registrationId
        externalId
        address
      }
    }
  }
`;

export const submitApprovalInvoice = gql`
  mutation ApproveInvoice(
    $invoiceId: String!
    $code: String!
    $note: String!
    $approval: ApprovalInvoiceStatus!
  ) {
    submitInvoiceApproval(
      invoiceId: $invoiceId
      code: $code
      note: $note
      approval: $approval
    )
  }
`;
