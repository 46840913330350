import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router";
import { hot } from "react-hot-loader";
import GoogleAnalytics from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/* Internal imports */

import PrivateRoute from "./components/routes/PrivateRoute";
import InvoicePage from "./components/pages/InvoicePage";
import LoginPage from "./components/pages/LoginPage";
import DashPage from "./components/pages/DashPage";
import Root from "./components/layout/Root";
import {
  GetLoggedInUserQuery,
  GetLoggedInUserQueryVariables,
  UserType,
} from "../types";
import { getLoggedInUser } from "./queries/user";
import { updateSession, clearSession, setFeatureFlag } from "./store/system";
import { withTracker } from "./utils/tracker";
import { AppState } from "./store";
import BasicUserPage from "./components/pages/BasicUserPage";
import PropertyFinancialsPage from "./components/pages/PropertyFinancialsPage";
import PropertyPage from "./components/pages/PropertyPage";
import DocumentPage from "./components/pages/DocumentPage";
import PropertyManagePage from "./components/pages/PropertyManagePage";
import InvoicesPage from "./components/pages/InvoicesPage";
import ContactManagePage from "./components/pages/ContactManagePage";
import ApproveInvoicePage from "./components/pages/ApproveInvoicePage";
import { useQuery } from "@apollo/client";

export const App = hot(module)(() => {
  const dispatch = useDispatch();
  const { loggedInUserType } = useSelector((state: AppState) => state.system);

  const location = useLocation();

  //Detection for bn
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.keyCode === 11) {
        toast.warn("Þú ert að nota BETA útgáfu af minn.eignarekstur.is");
        dispatch(setFeatureFlag({ name: "invoices", toggled: true }));
      }
    };
    window.addEventListener("keypress", handler);
    return () => {
      window.removeEventListener("keypress", handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const skipLoginCheckRoutes = ["/approveinvoice"];
  const shouldSkipQuery = skipLoginCheckRoutes.some((route) =>
    location.pathname.startsWith(route)
  );
  useQuery<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(
    getLoggedInUser,
    {
      skip: shouldSkipQuery,
      pollInterval: 30000,
      onCompleted: (data) => {
        data &&
          dispatch(
            updateSession({
              loggedInSocialNumber: data.me.socialNumber,
              loggedIn: true,
              loggingIn: false,
              tick: 0,
              loggedInUserType: data.me.userType,
            })
          );
        data && GoogleAnalytics.set({ userId: data.me.userId });
      },
      onError: (data) => {
        console.error("Error getting authentication session", data);
        // toast.error("Villa við að sækja auðkenningu fyrir íbúa.");
        dispatch(
          updateSession({
            sessionError: true,
          })
        );
        const logoutErrors = data.graphQLErrors
          .map((x) => {
            return x.message;
          })
          .filter(
            (x) =>
              x.includes("Not authorized") ||
              x.includes("Cannot find owner by socialNumber")
          );
        if (logoutErrors.length > 0) {
          dispatch(clearSession());
        }
      },
    }
  );

  return (
    <>
      <>
        <ToastContainer
          position="top-left"
          autoClose={4000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Root>
          {loggedInUserType &&
          loggedInUserType === (UserType.Basic as UserType) ? (
            <BasicUserPage />
          ) : (
            <Switch>
              <Route path="/login" component={withTracker(LoginPage)} />
              <Route
                path="/approveinvoice/:invoiceId"
                component={withTracker(ApproveInvoicePage)}
              />
              <PrivateRoute
                path="/invoice/:invoiceRequest"
                component={withTracker(InvoicePage)}
              />
              <PrivateRoute
                path="/invoices"
                component={withTracker(InvoicesPage)}
              />
              <PrivateRoute
                path="/property/:propertyId/financials"
                component={withTracker(PropertyFinancialsPage)}
              />
              <PrivateRoute
                path="/property/:propertyId/management"
                component={withTracker(PropertyManagePage)}
              />
              <PrivateRoute
                path="/property/:propertyId/contacts"
                component={withTracker(ContactManagePage)}
              />
              <PrivateRoute
                path="/property/:propertyId"
                component={withTracker(PropertyPage)}
              />
              <PrivateRoute
                path="/documents"
                component={withTracker(DocumentPage)}
              />
              <PrivateRoute path="/" component={withTracker(DashPage)} />
              <Route
                component={withTracker(() => (
                  <div>Not Found</div>
                ))}
              />
            </Switch>
          )}
        </Root>
      </>
    </>
  );
});

export default App;
