import React from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { CreditCard } from "styled-icons/evil";
import { Bill } from "styled-icons/remix-line";
import { H1 } from "../layout/Typography";
import { Loading } from "../layout/Loading";
import {
  GetPropertiesInvoicesForDashboardQuery,
  GetPropertiesInvoicesForDashboardQueryVariables,
} from "../../../types";
import { getPropertiesInvoicesForDashboard } from "../../queries/properties";
import isLocale from "date-fns/locale/is";
import { parseISO, isBefore, format } from "date-fns";
import {
  Table,
  TableHeader,
  TableTr,
  TableTh,
  TableBody,
} from "../layout/Table";
import { formatAmount } from "../utils/amount";
import { toBase64 } from "../../../utils/base64";
import {
  CardActionButton,
  CaretDownIcon,
  CaretRightIcon,
  CollapsedName,
  TitleContainer,
  TitleLeft,
  TitleRight,
} from "./CardCommon";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { setCollapseCard } from "../../store/layout/actions";
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Tooltip } from "../layout/Tooltip";

const LoadingCard = styled(Loading)`
  height: 200px;
`;

const Icon = styled(CreditCard)`
  color: #cecece;
  height: 52px;
  align-self: flex-start;
`;

const IconBrowse = styled(Bill)`
  color: #fff;
  height: 24px;
`;

const Container = styled.div`
  word-wrap: break-word;
  white-space: normal;
  padding: 15px;
`;

const InnerCard = styled.div`
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  border-bottom: 2px solid #dedede;
  background-color: #ffffff;
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

const BodyContainer = styled.div`
  padding: 15px;
`;

const CallToActionContainer = styled.div`
  text-align: right;
  margin-top: 24px;
`;

export type Props = {};

export type InvoiceWithPropertyId = {
  propertyId: string;
  propertyAddress: string;
  invoiceNo: string;
  socialNumber: string;
  amount: string;
  dueDate: string;
  remainingAmount: string;
};

export const InvoicesCard: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const invoicesResponse = useQuery<
    GetPropertiesInvoicesForDashboardQuery,
    GetPropertiesInvoicesForDashboardQueryVariables
  >(getPropertiesInvoicesForDashboard, {
    onError(...error) {
      toast.error(
        `Villa: Get ekki sótt upplýsingar um reikninga. Reyndu aftur síðar.`
      );
    },
    fetchPolicy: "no-cache",
  });

  let flattenedInvoices: InvoiceWithPropertyId[] = [];

  // push invoices that attached to the logged in user.
  (invoicesResponse.data?.properties || [])
    .map((property) => ({
      propertyId: property.id,
      propertyAddress: property.address,
      invoices: property.invoices,
    }))
    .forEach((property) => {
      property.invoices.forEach((i) => {
        flattenedInvoices.push({
          ...i,
          propertyId: property.propertyId,
          propertyAddress: property.propertyAddress,
        });
      });
    });

  // push invoices that are connected to the user
  (invoicesResponse.data?.ownerConnections?.flatMap((c) => c.properties) || [])
    .map((property) => ({
      propertyId: property.id,
      propertyAddress: property.address,
      invoices: property.invoices,
    }))
    .forEach((property) => {
      property.invoices.forEach((i) => {
        flattenedInvoices.push({
          ...i,
          propertyId: property.propertyId,
          propertyAddress: property.propertyAddress,
        });
      });
    });

  // Only show recent 10 invoices
  const flattenedInvoicesSliced =
    flattenedInvoices.length > 10
      ? flattenedInvoices
          .sort((a, b) =>
            isBefore(parseISO(b.dueDate), parseISO(a.dueDate)) ? -1 : 1
          )
          .slice(0, 10)
      : flattenedInvoices;

  /* COLLAPSE */
  const { collapsed_card } = useSelector((state: AppState) => state.layout);
  const CARD_KEY = "INVOICE_CARD";
  const DEFAULT_COLLAPSED = flattenedInvoices.length > 0;
  const CARD_COLLAPSED =
    collapsed_card[CARD_KEY] === undefined
      ? DEFAULT_COLLAPSED
      : collapsed_card[CARD_KEY];

  return (
    <Container>
      <InnerCard>
        {!invoicesResponse.loading ? (
          CARD_COLLAPSED ? (
            <>
              {/* Non-Collapsed Section, Showing everything */}
              <TitleContainer>
                <TitleLeft>
                  <Icon />
                  <CollapsedName>
                    <H1>Nýlegir reikningar</H1>
                    <p>{flattenedInvoicesSliced.length} reikningar sýnilegir</p>
                  </CollapsedName>
                </TitleLeft>
                <TitleRight>
                  <Tooltip tooltipMessage="Loka reikningafleka">
                    <CaretDownIcon
                      onClick={() => dispatch(setCollapseCard(CARD_KEY, false))}
                    />
                  </Tooltip>
                </TitleRight>
              </TitleContainer>
              <BodyContainer>
                {flattenedInvoicesSliced.length > 0 ? (
                  <Table>
                    <TableHeader>
                      <TableTr key="header-invoice">
                        <TableTh>Númer reiknings</TableTh>
                        <TableTh>Húsfélag</TableTh>
                        <TableTh>Upphæð</TableTh>
                        <TableTh className="hide-mobile">Eindagi</TableTh>
                        {/*
                            Disable until NAV is ready.
                        <TableTh className="hide-mobile">Greitt</TableTh>
                        */}
                      </TableTr>
                    </TableHeader>
                    <TableBody>
                      {flattenedInvoicesSliced.map((invoice, index) => {
                        const dueDateTime = parseISO(invoice.dueDate);
                        const isDueDate = isBefore(dueDateTime, new Date());
                        const isPaid = invoice.remainingAmount === "0";
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const showErrorColorSchema = !isPaid && isDueDate;
                        /* Disable error colors temporarly */
                        return (
                          <TableTr key={index}>
                            <TableTh>
                              <Link
                                to={`/invoice/${toBase64(
                                  `${invoice.invoiceNo}|${invoice.propertyAddress}`
                                )}`}
                              >
                                {invoice.invoiceNo}
                              </Link>
                            </TableTh>
                            <TableTh>{invoice.propertyAddress}</TableTh>
                            <TableTh>
                              {formatAmount(invoice.amount)} kr.
                            </TableTh>
                            <TableTh className="hide-mobile">
                              {format(
                                parseISO(invoice.dueDate),
                                "dd. MMMM yyyy",
                                {
                                  locale: isLocale,
                                }
                              )}
                            </TableTh>
                            {/*
                            Disable until NAV is ready.
                            <TableTh>{isPaid ? "Já" : "Nei"}</TableTh>*/}
                          </TableTr>
                        );
                      })}
                    </TableBody>
                  </Table>
                ) : (
                  <div>
                    Enginn virkur reikningur tengdur við innskráða kennitölu.
                  </div>
                )}
                <CallToActionContainer>
                  <CardActionButton
                    aria-label="Skoða reikninga"
                    buttonType="primary"
                    onClick={() => history.push("/invoices")}
                  >
                    <IconBrowse /> Skoða reikninga
                  </CardActionButton>
                </CallToActionContainer>
              </BodyContainer>
            </>
          ) : (
            <div>
              <TitleContainer>
                <TitleLeft>
                  <Icon />
                  <CollapsedName>
                    <H1>Nýlegir reikningar</H1>
                    <p>{flattenedInvoices.length} reikningar til staðar</p>
                  </CollapsedName>
                </TitleLeft>

                <TitleRight>
                  <Tooltip tooltipMessage="Opna reikningafleka">
                    <CaretRightIcon
                      onClick={() => dispatch(setCollapseCard(CARD_KEY, true))}
                    />
                  </Tooltip>
                </TitleRight>
              </TitleContainer>
            </div>
          )
        ) : (
          <LoadingCard loadingMessage="Hleð inn upplýsingum um reikningana þína" />
        )}
      </InnerCard>
    </Container>
  );
};
