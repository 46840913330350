import * as React from "react";
import { useLocation, useParams } from "react-router";
import {
  ApprovalInvoiceStatus,
  ApproveInvoiceMutation,
  ApproveInvoiceMutationVariables,
  GetApprovalInvoiceQuery,
  GetApprovalInvoiceQueryVariables,
} from "../../../graphql";
import {
  getApprovalInvoiceWithCode,
  submitApprovalInvoice,
} from "../../queries/invoice_approvals";
import { useLazyQuery, useMutation } from "@apollo/client";
import queryString from "query-string";
import isLocale from "date-fns/locale/is";
import { directClient } from "../../..";
import {
  Button,
  ButtonGroup,
  CheckIcon,
  Container,
  ExclamationIcon,
  FetchInvoiceLink,
  Header,
  InvoiceDetails,
  InvoiceHeader,
  InvoiceIframe,
  InvoiceRightDetails,
  NoteInput,
  NoteLabel,
  WrapperSimpleSubmit,
} from "./ApproveInvoicePage.styles";
import { formatAmount } from "../utils/amount";
import { format, parseISO } from "date-fns";
import { Loading } from "../layout/Loading";
import { toast } from "react-toastify";

export const Submitted: React.SFC<{
  className?: string;
  message?: string;
}> = ({ className, message }) => (
  <WrapperSimpleSubmit className={className}>
    <CheckIcon />
    {message && <div className="text">{message}</div>}
  </WrapperSimpleSubmit>
);

export const ApproveInvoicePage = () => {
  let { invoiceId } = useParams<{ invoiceId: string }>();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const code = queryParams.key;

  // Local state
  const [note, setNote] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [submitted, setSubmitted] = React.useState(false);

  const handleApprove = () => {
    submitApprovalInvoiceMutation({
      variables: {
        invoiceId,
        code: code as string,
        note,
        approval: ApprovalInvoiceStatus.Approved,
      },
    });
    setNote("");
    setSubmitted(true);
  };

  const handleReject = () => {
    submitApprovalInvoiceMutation({
      variables: {
        invoiceId,
        code: code as string,
        note,
        approval: ApprovalInvoiceStatus.Rejected,
      },
      client: directClient,
    });
    setNote("");
    setSubmitted(true);
  };

  const [submitApprovalInvoiceMutation, { loading: submitLoading }] =
    useMutation<ApproveInvoiceMutation, ApproveInvoiceMutationVariables>(
      submitApprovalInvoice,
      {
        onError: () => {
          toast.error(`Villa kom upp við að uppfæra upplýsingar um reikning`);
        },
      }
    );

  const [getApprovalInvoice, { data, error }] = useLazyQuery<
    GetApprovalInvoiceQuery,
    GetApprovalInvoiceQueryVariables
  >(getApprovalInvoiceWithCode, {
    onError: (error) => {
      console.error(error);
    },
    onCompleted: (data) => {
      setLoading(false);
    },
    client: directClient,
  });

  React.useEffect(() => {
    // Call API to validate the invoice
    // If valid, setValid(true)
    // setLoading(false)
    if (code) {
      getApprovalInvoice({
        variables: {
          id: invoiceId,
          code: code as string,
        },
      });
    }
  }, [code, invoiceId, getApprovalInvoice]);

  if (!code) {
    return <div>Invalid code</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (loading) {
    return <Loading loadingMessage="Sæki reikning" />;
  }

  if (submitLoading) {
    return <Loading loadingMessage="Vista reikning" />;
  }

  if (submitted) {
    return <Submitted message="Reikningi hefur verið svarað" />;
  }

  return (
    <Container>
      <Header>Samþykkja reikning</Header>
      <InvoiceHeader>
        <InvoiceDetails>
          <h2>{data?.approvalInvoice.name}</h2>
          <p>
            <div className="label">Innheimt til húsfélags</div>
            {data?.approvalInvoice.property?.address}
          </p>
          {data?.approvalInvoice.property?.registrationId && (
            <p>
              <div className="label">Skráningarnúmer</div>
              {data?.approvalInvoice.property?.registrationId}
            </p>
          )}
          <p>
            <div className="label">Sent frá</div>
            {data?.approvalInvoice.origin}
          </p>
          {data?.approvalInvoice.createDate && (
            <p>
              <div className="label">Reikningur skráður</div>
              {format(
                parseISO(data?.approvalInvoice.createDate),
                "dd. MMMM yyyy",
                {
                  locale: isLocale,
                }
              )}
            </p>
          )}
          {data?.approvalInvoice.description && (
            <p>
              <div className="label">Lýsing</div>
              {data?.approvalInvoice.description}
            </p>
          )}
          <p>
            <div className="label">Skráartýpa</div>
            {data?.approvalInvoice.mimetype}
          </p>
          <p>
            <div className="label">Staða á reikningi</div>
            {data?.approvalInvoice.status === "PENDING" ? (
              <span>Í bið (engin svörun)</span>
            ) : data?.approvalInvoice.status === "APPROVED" ? (
              <span className="approved">Samþykkt</span>
            ) : data?.approvalInvoice.status === "REJECTED" ? (
              <span className="rejected">Hafnað</span>
            ) : (
              <span>Staða ekki skráð</span>
            )}
          </p>
        </InvoiceDetails>
        <InvoiceRightDetails>
          <p>
            <div className="label">Upphæð</div>
            {formatAmount(data?.approvalInvoice.amount.toString() || "0")} kr.
          </p>
          <p>
            <div className="label">Eindagi</div>
            {data?.approvalInvoice.dueDate &&
              format(parseISO(data?.approvalInvoice.dueDate), "dd. MMMM yyyy", {
                locale: isLocale,
              })}
          </p>
          {data?.approvalInvoice.type && (
            <p>
              <div className="label">Flokkur</div>
              {data?.approvalInvoice.type}
            </p>
          )}
        </InvoiceRightDetails>
      </InvoiceHeader>
      <InvoiceIframe
        src={`/file_invoice/${data?.approvalInvoice.id}?code=${code}`}
        title="Reikningur"
      />

      <FetchInvoiceLink>
        <a href={`/file_invoice/${data?.approvalInvoice.id}?code=${code}`}>
          Sækja reikning
        </a>
      </FetchInvoiceLink>

      <NoteInput
        value={note}
        onChange={(e) => setNote(e.target.value)}
        placeholder="Bæta við ástæðu (valfrjálst)"
      />
      <NoteLabel>
        <ExclamationIcon />
        Ef reikningi er hafnað, vinsamlegast skráðu ástæðu hér að neðan
      </NoteLabel>
      <ButtonGroup>
        <Button approve onClick={handleApprove}>
          Samþykkja
        </Button>
        <Button onClick={handleReject} disabled={!note}>
          Hafna
        </Button>
      </ButtonGroup>
    </Container>
  );
};

export default ApproveInvoicePage;
